import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
    padding: 70px 0;
`;

const Contact = () => {
    return (
        <PageContainer>
            <h1>Contact Us</h1>
            <p>This is the Contact page. Provide your contact information here.</p>
        </PageContainer>
    );
};

export default Contact;
