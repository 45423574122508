import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
`;

const Header = styled.header`
    text-align: center;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    color: #f29f02;
    font-size: 2.5rem;
`;

const Description = styled.p`
    font-size: 1.2rem;
`;

const ServiceGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;    q
`;

const ServiceCard = styled.div`
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s;    
    &:hover {
        transform: translateY(-5px);
    }
`;

const ServiceTitle = styled.h2`
    color: #f29f02;
    font-size: 1.5rem;
    margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
    margin-bottom: 20px;
    text-align: justify
`;

const Button = styled.button`
    background: #f29f02;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: #357ab8;
    }
`;

const Service = () => {
    const services = [
        { title: 'IT Consultant', description: 'Our IT Consultant services are designed to help businesses leverage technology to achieve their strategic goals. With a team of experienced consultants, we provide tailored solutions that enhance efficiency, optimize processes, and drive innovation.' },
        { title: 'Web Development', description: 'We offer comprehensive web development services designed to create engaging, user-friendly websites that drive results. Our team of skilled developers, designers, and strategists work collaboratively to deliver tailored web solutions that align with your business goals.' },
        { title: 'Mobile Development', description: 'We specialize in mobile development services that create innovative, user-friendly applications for iOS and Android platforms. Our experienced team of developers and designers is dedicated to delivering high-quality mobile solutions tailored to meet your business needs.' },
        // { title: 'Service 4', description: 'Description of service 4.' },
    ];

    return (
        <Container>
            <Header>
                <Title>Our Services</Title>
                <Description>Explore the range of services we offer to help you succeed.</Description>
            </Header>
            <ServiceGrid>
                {services.map((service, index) => (
                    <ServiceCard key={index}>
                        <ServiceTitle>{service.title}</ServiceTitle>
                        <ServiceDescription>{service.description}</ServiceDescription>
                        {/* <Button>Learn More</Button> */}
                    </ServiceCard>
                ))}
            </ServiceGrid>
        </Container>
    );
};

export default Service;
