import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../helpers/Jwt';


const EntryEntity = () => {
    const { id, } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);

    {/* text, currency_idr, date, option, time, image */ }
    const [optionInputType, setOptionInputType] = useState([
        { value: 'text', label: 'Text' },
        { value: 'currency_idr', label: 'Mata Uang (Rp)' },
        { value: 'date', label: 'Tanggal' },
        { value: 'time', label: 'Jam' },
        { value: 'option', label: 'Pilihan' },
    ]);

    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        description: '',
        table: ''
    });

    const [columns, setColumns] = useState([{ id: 1, column: '', columnType: '', inputType: '', label: '' }]);

    useEffect(() => {
        fetchOptionProject();
    }, [id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            if (id) {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/system/entity/detail?id=${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                const { id_project, name, table, description } = response.data.data;
                const { columns: fetchedColumns, domain_project : domain } = response.data;
                
                setInitialValues({
                    id_project: id_project || '',
                    name: name || '',
                    table: table.replace(`${domain}_`, '') || '',
                    description: description || ''
                });

                // Set dynamic columns from fetched data
                if (fetchedColumns && fetchedColumns.length > 0) {
                    const mappedColumns = fetchedColumns.map((column, index) => ({
                        id: index + 1,
                        column: column.column || '',
                        columnType: column.column_type || '',
                        inputType: column.input_type || '',
                        label: column.label || '',
                    }));
                    setColumns(mappedColumns);
                }

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchOptionProject = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            setOptionProject(response.data);
            fetchData();
        } catch (error) {
            alert(error);
        } finally {
            setLoadingProject(false);
        }
    };

    const addField = () => {
        const newFields = [...columns];
        newFields.push({ id: newFields.length + 1, column: '', columnType: '', inputType: '', label: '' });
        setColumns(newFields);
    };

    const removeField = (id) => {
        const newFields = columns.filter((column) => column.id !== id);
        setColumns(newFields);
    };

    const handleInputChange = (id, columnKey, value) => {
        const newFields = columns.map((column) =>
            column.id === id ? { ...column, [columnKey]: value } : column
        );
        setColumns(newFields);
    };
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string().required('Description is required'),
        table: Yup.string().required('Table is required'),
    });

    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: values.id_project,
                name: values.name,
                description: values.description,
                table: values.table,
                active: 'Y',
                columns: JSON.stringify(columns)
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/entity`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/entity`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            }

            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Entry Form Entity</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="gap-4 px-4 py-2">
                            <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                Project
                            </label>
                            {loadingProject ? (
                                <h1>Loading...</h1>
                            ) : (
                                <>
                                    <Field
                                        as="select"
                                        name="id_project"
                                        className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        onChange={(e) => {
                                            const selectedProjectId = e.target.value;
                                            setFieldValue("id_project", selectedProjectId);
                                        }}
                                    >
                                        <option value="" label="Pilih Project" />
                                        {optionProject.map((project) => (
                                            <option key={project.id} value={project.id}>
                                                {project.name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="id_project" component="p" className="text-red-500 text-xs mt-1" />
                                </>
                            )}
                        </div>
                        <div className="gap-4 px-4 py-2">
                            <label htmlFor="name">Name</label>
                            <Field
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="name" />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="gap-4 px-4 py-2">
                            <label htmlFor="table">Table</label>
                            <Field
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" name="table" />
                            <ErrorMessage name="table" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="gap-4 px-4 py-2">
                            <label htmlFor="description">Description</label>
                            <Field
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="textarea" name="description" />
                            <ErrorMessage name="description" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className='flex justify-between my-2 px-4'>
                            <h1 className='p-2'>Column</h1>
                            <button
                                className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                                type="button"
                                onClick={() => addField('request')}>
                                <FontAwesomeIcon icon={faAdd} color="white" />
                            </button>
                        </div>
                        {columns.map((column) => (
                            <div key={column.id} className="flex items-center mb-2 flex-1 px-5">
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Column"
                                    type="text"
                                    name={`column_${column.id}`}
                                    value={column.column}
                                    onChange={(e) => handleInputChange(column.id, 'column', e.target.value)}
                                />
                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Column Type"
                                    type="text"
                                    name={`column_type_${column.id}`}
                                    value={column.columnType}
                                    onChange={(e) => handleInputChange(column.id, 'columnType', e.target.value)}
                                />
                                <Field
                                    as="select"
                                    name={`input_type_${column.id}`}
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    onChange={(e) => {
                                        setFieldValue(`input_type_${column.id}`, e.target.value); // Update Formik value
                                        handleInputChange(column.id, 'inputType', e.target.value)
                                    }}>
                                    <option value="" label="Input Type" />
                                    {optionInputType.map((inputType) => (
                                        <option key={inputType.value} value={inputType.value}>
                                            {inputType.label}
                                        </option>
                                    ))}
                                </Field>

                                <input
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    placeholder="Label"
                                    type="text"
                                    name={`label_${column.id}`}
                                    value={column.label}
                                    onChange={(e) => handleInputChange(column.id, 'label', e.target.value)}
                                />

                                <button
                                    className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2"
                                    onClick={() => removeField(column.id)}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} color="white" />
                                </button>
                            </div>
                        ))}

                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryEntity;
