// src/helpers/Jwt.js

// Mengambil token dari local storage
export const getToken = () => localStorage.getItem('authToken');

// Menyimpan token di local storage
export const setToken = (token) => localStorage.setItem('authToken', token);

// Menghapus token dari local storage
export const removeToken = () => localStorage.removeItem('authToken');

// Decode token jika diperlukan
export const decodeToken = () => {
    const token = getToken();
    if (!token) throw new Error('No token found');
    const payload = token.split('.')[1];
    return JSON.parse(atob(payload));
};
