import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../helpers/Jwt';

const EntryAccess = () => {
    const { id, } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();    
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState({});
    const [optionUser, setOptionUser] = useState([]);
    const [loadingUser, setLoadingUser] = useState(true);

    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);

    const [dataMenu, setDataMenu] = useState([]);
    const [loadingMenu, setLoadingMenu] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        link: '',
    });

    useEffect(() => {
        fetchOptionProject();
    }, [id]);

    const fetchOptionProject = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            setOptionProject(response.data);                
            // fetchData();
        } catch (error) {
            alert(error);
        } finally {
            setLoadingProject(false);
        }
    };        

    const fetchDataMenu = async (projectID) => {
        setLoadingMenu(true);
        try {            
            await axios.get(`${process.env.REACT_APP_API_URL}/api/system/access?id_project=${projectID}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }).then( response => {
                const newData = response.data.data.map((menu) =>
                    ({ "id": menu.id, "label": menu.name, "checked": false })
                );
                setDataMenu(newData);
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingMenu(false);
        }
    };

    const fetchOptionUser = async (projectID) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/user/user?id_project=${projectID}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            setOptionUser(response.data);            
        } catch (error) {
            alert(error);
        } finally {
            setLoadingUser(false);
        }
    };

    // Handler to toggle checkbox state
    const handleCheckboxChange = (id) => {
        setDataMenu((prevCheckboxes) =>
        prevCheckboxes.map((checkbox) =>
            checkbox.id === id
            ? { ...checkbox, checked: !checkbox.checked }
            : checkbox
        )
        );
    };

    const validationSchema = Yup.object().shape({
        // name: Yup.string().required('Name is required'),
        // category: Yup.string().required('Category is required'),
        // description: Yup.string().required('Description is required'),
    });

    const getIdsAs = (array) => {
        return array.map(obj => obj.id);
    }

    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_user: values.id_user,
                id_menu: getIdsAs(dataMenu)
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/system/access`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/system/access`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            }
            
            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Entry Form Menu</h2>
            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                    <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                        <div className='flex-1 px-2'>
                            <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                Project
                            </label>
                            {loadingProject ? (
                                <h1>Loading...</h1>
                            ) : (
                                <>
                                    <Field
                                        as="select"
                                        name="id_project"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        onChange={(e) => {
                                            const selectedProjectId = e.target.value;
                                            setFieldValue("id_project", selectedProjectId); // Update Formik value
                                            fetchOptionUser(selectedProjectId); // Call fetchOptionOutlet with the selected project ID
                                            fetchDataMenu(selectedProjectId);
                                        }}
                                    >
                                        <option value="" label="Pilih Project" />
                                        {optionProject.map((project) => (
                                            <option key={project.id} value={project.id}>
                                                {project.name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="id_project" component="p" className="text-red-500 text-xs mt-1" />
                                </>
                            )}
                        </div>
                        <div className='flex-1 px-2'>
                            <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                Pengguna
                            </label>
                            {loadingUser ? (
                                <h1>Loading...</h1>
                            ) : (
                                <>
                                    <Field
                                        as="select"
                                        name="id_user"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        onChange={(e) => {
                                            const selectedUserId = e.target.value;
                                            setUser(selectedUserId);
                                            setFieldValue("id_user", selectedUserId);                                            
                                        }}
                                    >
                                        <option value="" label="Pilih Pengguna" />
                                        {optionUser.map((user) => (
                                            <option key={user.id} value={user.id}>
                                                {user.name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="id_user" component="p" className="text-red-500 text-xs mt-1" />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="gap-4 px-5 py-2">
                        {dataMenu?.map((menu) => (
                            <div key={menu.id}>
                                <label>
                                <input
                                    type="checkbox"
                                    defaultChecked={menu.checked}
                                    onChange={() => handleCheckboxChange(menu.id)}
                                /> &nbsp;{menu.label}
                                </label>
                            </div>
                        ))}
                    </div>
                    <div className="mt-4 px-5">
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryAccess;
