import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCalculator, faLock } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../../component/Table/DataTable';
import { confirmDelete } from '../../../helpers/PromptUtils';
import { formatTimeWIB } from '../../../helpers/DataUtils';
import { formatCurrency } from '../../../helpers/CurrencyInput';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, getYear, getMonth } from 'date-fns';

const Salary = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Pegawai',
            accessor: 'name',
        },
        {
            Header: 'Tanggal Gajian',
            accessor: 'where',
        },
        {
            Header: 'Nominal Gaji',
            accessor: 'salary_amount',
            Cell: ({ value }) => formatCurrency(parseInt(value), 'IDR'),
        },
        {
            Header: 'Nominal Lembur',
            accessor: 'overtime_amount',
            Cell: ({ value }) => formatCurrency(parseInt(value), 'IDR'),
        },
    ];

    // Fetch data from API
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}api/core/attendance/salary?id_project=1&month=${startDate}`)
            .then(response => response.json())
            .then(data => {
                setData(data); // Set fetched data
                setLoading(false); // Data fetching complete
            })
            .catch(error => {
                setError(error); // Handle errors
                setLoading(false); // Data fetching complete
            });                    
    }, [startDate]);

    const actions = [{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faLock} color="white" />
        </button>,
        callback: (rowData) => handleCalculate(rowData)
    }];

    const handleCalculate = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to calculate this payslip?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_API_BASE_URL}api/core/attendance/salary/calculate`,
                data: {
                    id_hs_employee: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    };

    const handleChange = (date) => {
        setStartDate(format(date, 'yyyy-MM'));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <div className='flex justify-between px-5'>
                <h1>Salary</h1>
                <div>
                    <DatePicker
                        id="date-picker"
                        selected={startDate}
                        onChange={handleChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        placeholderText="Select month and year"
                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                    />
                </div>
            </div>
            <DataTable
                columns={columns}
                data={data}
                actions={actions}
            />
        </div>
    );
};

export default Salary;
