import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../helpers/Jwt';
import ImageUploadModal from '../../helpers/image-upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

const EntryProject = () => {
    const { id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
        name: '',
        company: '',
        address: '',
        logo: '',
    });

    const [imgLogo, setImgLogo] = useState('');
    const [openCropLogo, setOpenCropLogo] = useState(false);
    const [imgLogoCropped, setImgLogoCropped] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (id) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/core/project/project/detail?id=${id}`, {
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${getToken()}`
                        }
                    });
                    const { name, company, address, logo } = response.data;
                    setInitialValues({
                        name: name || '',
                        company: company || '',
                        address: address || '',
                        // logo: logo || '', // Save logo URL if exists
                    });

                    // setImgLogoCropped(`${process.env.REACT_APP_API_URL}upload/logo/${logo}`);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error if needed (e.g., show a notification)
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters long'),
        company: Yup.string()
            .required('Company is required'),
        address: Yup.string()
            .required('Address is required'),
    });

    // Handle form submission
    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = {
                ...values,
                id: id || undefined, // Include id if it exists
                logo: imgLogoCropped
            };

            await axios.post(`${process.env.REACT_APP_API_URL}api/core/project/project`, formData, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            navigate(-1); // Navigate back after submission
        } catch (error) {
            alert(error.response.data)
        } finally {
            setSubmitting(false);
        }
    };

    const handleFotoChange = (e, setter, key) => {
        e.preventDefault();
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setter(reader.result);
            setOpenCropLogo(true);            
        };

        reader.readAsDataURL(file);
    };

    const handleCroppedFoto = (croppedImage, identifier) => {
        setImgLogoCropped(croppedImage);        
    };

    function closeModal() {
        setOpenCropLogo(false);
    }

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Project</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-4 px-2">
                            <label htmlFor="name">Name</label>
                            <Field
                                name="name"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="company">Perusahaan</label>
                            <Field
                                name="company"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="company" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="address">Alamat</label>
                            <Field
                                name="address"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="address" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="image_ktp" className="block text-gray-700 text-sm font-bold mb-2">
                                Logo Project
                                <div className="mb-4 mt-2">
                                    {imgLogoCropped ? (
                                        <img src={imgLogoCropped} alt="Uploaded" className="object-contain lg:w-64 md:w-64 sm:w-32 rounded " />
                                    ) : <FontAwesomeIcon icon={faUpload} size='7x' />}
                                </div>
                            </label>

                            <input
                                style={{ display: 'none' }}
                                type="file"
                                id="image_ktp"
                                accept="image/*"
                                onChange={(e) => handleFotoChange(e, setImgLogo, 'ktp')}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />

                            <ImageUploadModal
                                modalOpen={openCropLogo}
                                onClose={closeModal}
                                fileOpen={imgLogo}
                                onCroped={handleCroppedFoto}
                                identifier="logo"
                            />
                        </div>
                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryProject;
