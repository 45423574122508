import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
`;

const Header = styled.header`
    text-align: center;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    color: #f29f02;
    font-size: 2.5rem;
`;

const Description = styled.p`
    font-size: 1.2rem;
`;

const ServiceGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
`;

const ServiceCard = styled.div`
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s;

    &:hover {
        transform: translateY(-5px);
    }
`;

const ServiceTitle = styled.h2`
    color: #f29f02;
    font-size: 1.5rem;
    margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
    margin-bottom: 20px;
    text-align: justify
`;

const Button = styled.button`
    background: #f29f02;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: #357ab8;
    }
`;

const Company = () => {

    return (
        <Container>
            <Header>
                <Title>Our Company</Title>
                <br/>
                <Description>
                    Perusahaan ini memberikan penawaran yang memungkinkan anda untuk fokus pada hanya inovasi dan pengembangan tanpa memikirkan permasalahan-permasalahan yang seharusnya bukan sebuah masalah
                </Description>
            </Header>
        </Container>
    );
};

export default Company;
