import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../helpers/Jwt';

const EntryUser = () => {
    const { id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loadingProject, setLoadingProject] = useState(true);

    const [optionProject, setOptionProject] = useState([]);
    const [selectedOptionProject, setSelectedOptionProject] = useState('');

    const [initialValues, setInitialValues] = useState({
        id: '',
        id_project: '',
        name: '',
        username: '',
        password: '',
        confirm_password: '',
        role_user: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (id) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/user/user/detail?id=${id}`, {
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${getToken()}`
                        }
                    });
                    const { name, username, role_user } = response.data;
                    setInitialValues({
                        id: id || '',
                        name: name || '',
                        username: username || '',
                        role_user: role_user || '',
                    });                
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchOptions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                setOptionProject(response.data);                
            } catch (error) {
                alert(error);
            } finally {
                setLoadingProject(false);
            }
        };

        fetchOptions();
        fetchData();
    }, [id]);

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        id_project: Yup.string()
            .required('Project is required'),

        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters long'),
        
        username: Yup.string()
            .required('Username is required')
            .min(4, 'Username must be at least 4 characters long')
            .matches(/^[a-zA-Z0-9_]+$/, 'Username can only contain letters, numbers, and underscores'),
        
        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[\W_]/, 'Password must contain at least one special character'),
        
        confirm_password: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        
        role_user: Yup.string()
            .required('Role user is required')
            // .oneOf(['admin', 'user'], 'Role user must be either admin or user'),
    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: values.id_project,
                name: values.name,
                username: values.username,
                password: values.password,
                role_user: values.role_user
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/user/user`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/user/user`, formData);
            }
            
            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form User</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, getFieldProps }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                Project
                            </label>
                            <Field
                                as="select"
                                name="id_project"
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                            >
                                <option value="" label="Pilih Project" />
                                {optionProject.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.name}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="id_project" component="p" className="text-red-500 text-xs mt-1" />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="name">Name</label>
                            <Field
                                name="name"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="username">Username</label>
                            <Field
                                name="username"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="username" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="password">Password</label>
                            <Field
                                name="password"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="password" />
                            <ErrorMessage name="password" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="confirm_password">Password</label>
                            <Field
                                name="confirm_password"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="password" />
                            <ErrorMessage name="confirm_password" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="role_user">Role User</label>
                            <Field
                                name="role_user"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="role_user" component="p" className='text-red-500 text-xs mt-1' />
                        </div>                    
                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryUser;
