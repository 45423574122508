import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { getToken } from "../../helpers/Jwt";
import { confirmDelete } from "../../helpers/PromptUtils";

const Sidebar = ({ user }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { logout } = useAuth();
    const [open, setOpen] = useState(true);
    const [menus, setMenus] = useState([]);
    const [activeMenu, setActiveMenu] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        if (user != null) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}api/system/menu`,
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            }).then(function (response) {
                setMenus(response.data.data);
                setLoading(false);
            });
        }
    }, [user]);

    const handleMenuClick = (index) => {
        setActiveMenu(null);
        if (menus[index].link) {
            navigate(menus[index].link);
        } else {
            setActiveMenu(activeMenu === index ? null : index);
        }
    };

    const handleLogout = () => {
        const confirmed = confirmDelete('Are you sure you want to leave this apps?');
        if (confirmed) {
            logout();
        }
    };

    return (
        <div className="flex">
            {loading && <p>Loading...</p>}

            {!loading &&
            
                <div style={{
                    height:'100%', minHeight: '100vh'
                }} className={` ${open ? "w-72" : "w-20"} ${user?.user?.role_user == 'superadmin' ? 'bg-[#973131]' : 'bg-[#597E52]'} h-screen p-5 pt-8 relative duration-300`}>
                    <img
                        src={`/assets/burger-menu.svg`}
                        className={`bg-white absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple border-2 rounded-full ${!open && "rotate-180"}`}
                        onClick={() => setOpen(!open)}
                    />

                    <div className="gap-x-4 items-center">
                        <div className="flex items-center">
                            <img
                                src={`/assets/icon_network.png`}
                                alt="Logo"
                                className="w-10 h-auto mr-3"
                            />
                            <h1 className={`text-white origin-left font-medium text-xl duration-200 ${!open ? "scale-0" : "scale-100"}`}>
                                Hijrah SuperApp
                            </h1>
                        </div>
                        <p className={`text-white duration-200 ${!open && "scale-0"}`}>
                            Masuk Sebagai, {user?.user?.name}
                        </p>
                    </div>

                    <ul className="pt-6">
                        {menus.map((menu, index) => (
                            <div key={index}>
                                <div
                                    className={`flex rounded-md p-2 cursor-pointer hover:bg-[#C6A969] text-gray-300 text-sm items-center gap-x-4 ${menu.gap ? "mt-9" : "mt-2"} ${activeMenu === index || (menu.link && location.pathname === menu.link) ? "bg-[#C6A969]" : ""}`}
                                    onClick={() => handleMenuClick(index)}
                                >
                                    <img
                                        src={menu.icon}
                                        alt="Menu Icon"
                                        className="w-10 h-auto mr-3"
                                    />
                                    <span className={`${!open && "hidden"} origin-left duration-200 text-white`}>
                                        {menu.name}
                                    </span>
                                </div>

                                {activeMenu === index && menu.submenus && (
                                    <ul className="pl-5">
                                        {menu.submenus.map((submenu, subIndex) => (
                                            <Link 
                                                to={submenu.link} 
                                                key={subIndex}
                                                className={`flex rounded-md p-2 cursor-pointer hover:bg-[#C6A969] text-gray-300 text-sm items-center gap-x-4 mt-2 ${location.pathname === submenu.link ? "bg-[#C6A969]" : ""}`}
                                            >
                                                <span className={`${!open && "hidden"} origin-left duration-200 text-white`}>
                                                    {submenu.name}
                                                </span>
                                            </Link>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        ))}

                        <div
                            className={`flex rounded-md py-3 px-2 cursor-pointer hover:bg-[#C6A969] text-gray-300 text-sm items-center gap-x-4`}
                            onClick={handleLogout}
                        >
                            <img
                                src={`/assets/logout.png`}
                                alt="Logout Icon"
                                className="w-10 h-auto mr-3"
                            />
                            <span className={`origin-left duration-200 text-white`}>
                                Logout
                            </span>
                        </div>
                    </ul>
                </div>
            }
        </div>
    );
};

export default Sidebar;
