import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)'
  }
};


const ImageUploadModal = ({ modalOpen, onClose, fileOpen, onCroped, identifier }) => {
  const cropperRef = useRef(null);

  const onCropCompleted = () => {
    const cropper = cropperRef.current?.cropper;
    onCroped(cropper.getCroppedCanvas().toDataURL(), identifier);
    onClose();
  };

  return (
    <Modal
    ariaHideApp={false}
      isOpen={modalOpen}
      onRequestClose={onClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <button onClick={onClose} style={{ float: 'right' }}>
        <FontAwesomeIcon icon={faClose} />
      </button>

      <div className="modal mt-6">
        <div className="modal-content mb-4">
          <h2 className="mb-2">Atur Foto</h2>
          <div className="cropper-container">
            <Cropper
              style={{ height: 500, width: 500 }}
              ref={cropperRef}
              src={fileOpen}
              alt="Crop File"
              aspectRatio={1}
              guides={true}
              // crop={onCropCompleted}
            />

          </div>
        </div>
        <button style={{ float: 'right' }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onCropCompleted}>Selesai</button>
      </div>

    </Modal>
  );
};

export default ImageUploadModal;
