import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../helpers/Jwt';


const EntryProcedure = () => {
    const { id, } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        description: '',
        query: ''
    });

    useEffect(() => {
        fetchOptionProject();
    }, [id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            if (id) {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/system/procedure/detail?id=${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                const { id_project, name, query, description } = response.data.data;
                const { domain_project : domain } = response.data;

                setInitialValues({
                    id_project: id_project || '',
                    name: name || '',
                    query: query.replace(`${domain}_`, '') || '',
                    description: description || ''
                });                
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchOptionProject = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            setOptionProject(response.data);
            fetchData();
        } catch (error) {
            alert(error);
        } finally {
            setLoadingProject(false);
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),        
        query: Yup.string().required('Query is required'),
    });

    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: values.id_project,
                name: values.name,
                description: values.description,
                query: values.query,
                active: 'Y',
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/procedure`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/procedure`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            }

            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4">
            <h2 className="text-2xl font-bold mb-4">Entry Form Entity</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="gap-4 px-4 py-2">
                            <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                Project
                            </label>
                            {loadingProject ? (
                                <h1>Loading...</h1>
                            ) : (
                                <>
                                    <Field
                                        as="select"
                                        name="id_project"
                                        className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        onChange={(e) => {
                                            const selectedProjectId = e.target.value;
                                            setFieldValue("id_project", selectedProjectId);
                                        }}
                                    >
                                        <option value="" label="Pilih Project" />
                                        {optionProject.map((project) => (
                                            <option key={project.id} value={project.id}>
                                                {project.name}
                                            </option>
                                        ))}
                                    </Field>
                                    <ErrorMessage name="id_project" component="p" className="text-red-500 text-xs mt-1" />
                                </>
                            )}
                        </div>
                        <div className="gap-4 px-4 py-2">
                            <label htmlFor="name">Name</label>
                            <Field
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                name="name" />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="gap-4 px-4 py-2">
                            <label htmlFor="query">Query</label>
                            <Field
                                className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                name="query"
                                as="textarea"
                                rows={10} />
                            <ErrorMessage name="query" component="p" className='text-red-500 text-xs mt-1' />
                        </div>

                        <div className="m-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryProcedure;
