import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { formatCurrency } from '../../../helpers/CurrencyInput';
import Grid from '../../../component/Grid/Grid';
import './Style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBeerMugEmpty, faCartPlus, faCloud, faCloudBolt, faMartiniGlassEmpty, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import List from '../../../component/List/List';

const Sale = () => {
    const [data, setData] = useState({
        products: [],
        cart: [],
        loading: true,
        error: null
    });

    const [cartUpdated, setCartUpdate] = useState(false);

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [productsResponse, cartResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/base/product`),
                    axios.get(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/base/cart`)
                ]);

                setData({
                    products: productsResponse.data,
                    cart: cartResponse.data,
                    loading: false,
                    error: null
                });
            } catch (err) {
                setData({
                    products: [],
                    cart: [],
                    loading: false,
                    error: err
                });
            }
        };

        fetchData();
    }, [cartUpdated]);

    // Increment and decrement quantity handlers
    const addItem = async (id) => {
        setCartUpdate(false);
        axios.post(`${process.env.REACT_APP_API_URL}api/dashboard/base/cart`, {
            "id_project": "1",
            "id_product": id,
            "quantity": 1
        }).then((value) => {
            setCartUpdate(true);
            console.log(value);
        });
    };

    // Increment and decrement quantity handlers
    const incrementQuantity = async (id) => {
        setCartUpdate(false);
        axios.post(`${process.env.REACT_APP_API_URL}api/dashboard/base/cart/increase`, {
            "id_project": "1",
            "id_product": id
        }).then((value) => {
            setCartUpdate(true);
            console.log(value);
        });
    };

    const decrementQuantity = (id) => {
        setCartUpdate(false);
        axios.post(`${process.env.REACT_APP_API_URL}api/dashboard/base/cart/decrease`, {
            "id_project": "1",
            "id_product": id
        }).then((value) => {
            setCartUpdate(true);
            console.log(value);
        });
    };

    const renderItem = (item, exclude, displayKey = true) => {
        const itemsArray = Object.entries(item).map(([key, value]) => ({ key, value }));

        const excludedKeys = new Set(exclude);

        const filteredItems = itemsArray.filter(({ key }) =>
            !excludedKeys.has(key) && key !== 'id' && key !== 'id_product'
        );

        if (filteredItems.length === 0) {
            return <p>No items to display</p>;
        }

        return (
            <div className="card-grid">
                {filteredItems.map(({ key, value }) => (
                    <div key={key} className='d-flex text-left mb-1'>
                        {displayKey && <p className='text-sm'>{key}</p>}
                        <p>
                            <strong>
                                {key === 'price' ? `Rp${formatCurrency(value)}` : value}
                            </strong>
                        </p>
                    </div>
                ))}
                <div className="item-controls mt-2">
                    <button className='text-right fa-pull-right text-white text-xs rounded focus:ring bg-green-500 hover:bg-green-700 p-2'
                        onClick={() => addItem(item.id)}>
                        <FontAwesomeIcon icon={faCartPlus} color="white" />
                    </button>
                </div>
            </div>
        );
    };

    const renderItemCart = (item) => {
        const itemsArray = Object.entries(item).map(([key, value]) => ({ key, value }));

        const excludedKeys = new Set(['no_transaction', 'id_project', 'image', 'active', 'created_at', 'updated_at', 'updated_by', 'description']);

        const filteredItems = itemsArray.filter(({ key }) =>
            !excludedKeys.has(key) && key !== 'id' && key !== 'id_product'
        );

        if (filteredItems.length === 0) {
            return <p>No items to display</p>;
        }

        return (
            <div className="card-grid rounded">
                <div className="flex justify-between">
                    <div className="item-details">
                        <p className="text-lg font-semibold">{item.name}</p>
                        <p className="text-sm">
                            <strong>{`Rp${formatCurrency(item.price)}`}</strong>
                        </p>
                    </div>
                    <div className="flex items-center ml-4">
                        <button
                            className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2'
                            onClick={() => decrementQuantity(item.id_product)}
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className="mx-2 text-sm">{item.quantity || 1}</span>
                        <button
                            className='text-white text-xs rounded focus:ring bg-green-500 hover:bg-green-700 p-2'
                            onClick={() => incrementQuantity(item.id_product)}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const submitTransaction = async () => {
        setCartUpdate(false);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}api/dashboard/base/transaction`, {
                'id_transaction': 1
            });
            setCartUpdate(true);
        } catch (error) {
            console.log(error);
        }
    }

    if (data.loading) {
        return <div>Loading...</div>;
    }

    if (data.error) {
        return <div>Error: {data?.error?.message}</div>;
    }

    return (
        <div className="flex flex-col h-screen p-3">
            <div className="flex justify-between mb-4">
                <h1 className="text-xl font-bold">Penjualan</h1>
            </div>
            <div className="flex flex-1 gap-4">
                <div className="flex-1 p-3 bg-white shadow rounded">
                    {data?.products?.length === 0 ? (
                        <div className='text-center mt-3'>
                            <FontAwesomeIcon icon={faCloud} color='grey' fontSize={40}/>
                            <p className="text-center text-gray-600">Data Produk tidak ditemukan</p>
                        </div>
                    ) : (
                        <Grid
                            columns={4}
                            items={data?.products?.map(value =>
                                renderItem(value, ['no_transaction', 'id_project', 'description', 'image', 'active', 'created_at', 'updated_at', 'updated_by'])
                            )}
                        />
                    )}
                </div>
                <div className="w-1/3 p-3 bg-accent shadow rounded">
                    <div className="overflow-y-auto" style={{ height: '80vh' }}>
                        {data?.cart?.length === 0 ? (
                            <div className='text-center mt-3'>
                                <FontAwesomeIcon icon={faCloud} color='grey' fontSize={40}/>
                                <p className="text-center text-gray-600">Keranjang Belanja kosong</p>
                            </div>
                        ) : (
                            <List items={data?.cart?.map(renderItemCart)}/>
                        )}
                        
                    </div>
                    <button
                        className="w-full py-3 mt-4 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition"
                        onClick={submitTransaction}
                    >
                        <FontAwesomeIcon icon={faCartPlus} className="mr-2" /> Pesan
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sale;