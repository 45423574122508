import React from 'react';
import PropTypes from 'prop-types';

const CustomInput = ({ value, onChange, onBlur, ...props }) => {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <input
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            {...props}
        />
    );
};

CustomInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
};

export default CustomInput;
