import React, { useEffect, useState } from 'react';
import DataTable from "./Table/DataTable";
import Grid from "./Grid/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import List from "./List/List";
import { ErrorMessage, Field, Form, Formik } from "formik";
import CurrencyInput from "../helpers/CurrencyInput";
import { Link } from "react-router-dom";
import axios from "axios";
import { confirmDelete } from '../pages/Activity/Helpers/PromptUtils';
import ActionComponent from '../pages/Activity/Component/ActionComponent';
import * as Yup from 'yup';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

const RenderComponent = ({ component, type, attribute, actionRemove, actionEdit, actionRequest, callRequest, enableCustom, callChange }) => {
    const { id_module, component_id: id, component_request: request } = component;
    const [actions, setActions] = useState([]);
    const Container = ({ label, content }) => {
        return (
            <div className="card">
                {enableCustom &&
                    <div className="rounded bg-gray-100 border-b border-gray-200 flex px-4 py-2">
                        <h2 className="text-lg font-semibold text-gray-900 flex-1">{label}</h2>
                        <button
                            className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1"
                            onClick={() => actionRemove(component)}>
                            <FontAwesomeIcon icon={faTrashAlt} color="white" />
                        </button>
                        <button
                            data-tip="This is a tooltip"
                            className="text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1"
                            onClick={() => actionEdit(component)}>
                            <FontAwesomeIcon icon={faEdit} color="white" />
                        </button>

                        {actionRequest && <button
                            data-tip="This is a tooltip"
                            className="text-xs rounded focus:ring bg-green-500 hover:bg-green-700 p-2 mx-1"
                            onClick={() => actionRequest(component)}>
                            <FontAwesomeIcon icon={faPaperPlane} color="white" />
                        </button>}

                        {enableCustom == true && type == 'form' && (
                            <Link
                                data-tip="This is a tooltip"
                                className="text-xs rounded focus:ring bg-green-500 hover:bg-green-700 p-2 mx-1"
                                to={`/activity-component/${id_module}/${id}`}>
                                <FontAwesomeIcon icon={faFolderPlus} color="white" />
                                {/* <Link 
                                    className="text-xs bg-slate-200 rounded p-2 m-2 hover:bg-gray-700" 
                                    to={`/activity-component/${id_module}/${id}`}>+ Component</Link> */}
                            </Link>
                        )}
                    </div>}
                <div className="p-4 w-full">
                    {content}
                </div>
            </div>
        );
    }

    useEffect(() => {
        const fetchActions = async () => {
            if (id) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/action?id_parent=${id}`);
                    setActions(response.data.action);
                } catch (error) {
                    console.error(`Error executing action for component ${id}:`, error);
                }
            }
        };

        fetchActions();
    }, [])

    useEffect(() => {
        const sortActions = async () => {
            try {
                const ids = actions.map(item => item.id);
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/action/sort`, {
                    "component_sort" : ids
                });
            } catch (error) {
                console.error(`Error executing action for component ${id}:`, error);
            }
        }

        if (actions.length > 0 && enableCustom) {
            sortActions();
        }
    }, [actions]);

    const attributes = { ...attribute };

    switch (type) {
        case 'form':

            let validationSchema;
            let initialValues = {};
            const schemaObject = {};

            actions.forEach(input => {
                const attributes = input.action_attribute;
                const nameField = attributes.find(attr => attr.key === 'name');
                const typeField = attributes.find(attr => attr.key === 'type');

                if (nameField && typeField) {
                    let yupType;
                    switch (typeField.value) {
                        case 'date':
                            yupType = Yup.date();
                            break;
                        case 'number':
                            yupType = Yup.number();
                            break;
                        default:
                            yupType = Yup.string(); // default type
                    }
                    schemaObject[nameField.value] = yupType.required(`${nameField.value} is required`);

                    // Set initial values based on the action attributes
                    initialValues[nameField.value] = ''; // Default initial value can be set here
                }
            });

            validationSchema = Yup.object().shape(schemaObject);

            const onSubmitComponent = async (values, { setSubmitting }) => {

                values['url'] = request.url;
                try {
                    await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/request`, values);
                } catch (error) {
                    console.error('Error submitting form:', error);
                } finally {

                }
            };

            const onSortEnd = (oldIndex, newIndex) => {
                console.log(oldIndex, newIndex);
                setActions((array) => arrayMoveImmutable(array, oldIndex, newIndex));
            };            

            return (
                <Container label={component?.title ?? "Button"} content={
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitComponent}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <SortableList allowDrag={enableCustom} onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                                    {actions.map((action, index) => {
                                        const nameAttribute = action.action_attribute.find(attr => attr.key === 'name');
                                        return <SortableItem key={action.id} index={index}>
                                                    <div style={{ cursor: 'pointer' }} >
                                                        <ActionComponent
                                                            callChange={(value) => {
                                                                setFieldValue(nameAttribute.value, value)
                                                            }} key={index} action={action} />
                                                    </div>
                                                </SortableItem>
                                    })}
                                </SortableList>
                            </Form>
                        )}
                    </Formik>
                } />
            );
        case 'button':
            if (attributes.type == "submit") {
                return (
                    <Container label={component?.title ?? "Button"} content={<button {...attributes}>{attribute.child}</button>} />
                );
            } else {
                return (
                    <Container label={component?.title ?? "Button"} content={<button {...attributes} onClick={callRequest}>{attribute.child}</button>} />
                );
            }
        case 'input':
            const inputField = (
                <div className="gap-4 px-4 py-2">
                    {attributes.type == 'currency_idr' ? <CurrencyInput
                        id={attributes.name}
                        name={attributes.name}
                        onChange={callChange}
                        currency="IDR"
                        placeholder="Masukkan jumlah"
                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                    /> : <Field
                        type={attributes.type}
                        name={attributes.name}
                        placeholder={attributes.placeholder}
                        className={attributes.className}
                    />}

                    <ErrorMessage
                        name={attributes.name}
                        component="p"
                        className="text-red-500 text-xs mt-1"
                    />
                </div>
            );
            return enableCustom ? (
                <Container label={component?.title ?? "Input"} content={inputField} />
            ) : inputField;
        case 'text':
            return <p>{component}</p>;
        case 'header':
            return <header>{component}</header>;
        case 'footer':
            return <footer style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
            }}>{component}</footer>;
        case 'card':
            return (
                <div className="card">
                    {component}
                </div>
            );
        case 'section':
            return (
                <section>{component}</section>
            );
        case 'grid':
            return (
                <Container label={component?.title ?? "Grid"} content={<Grid items={component.items} columns={attribute.columns} />} />
            );
        case 'table':
            return (
                <Container label={component?.title ?? "Table"} content={<DataTable data={component.items} columns={component.columns} />} />
            );
        case 'list':
            return (
                <Container label={component?.title ?? "List"} content={<List items={component.items} />} />
            )
        default:
            return null;
    }
};

export default RenderComponent;