import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DynamicComponent from './Component/DynamicComponent';
import axios from 'axios';
import { decodeToken } from '../../helpers/Jwt';

const Activity = () => {
  const [custom, setEnableCustom] = useState(true);
  const { link } = useParams(); // Get 'link' from URL params
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = decodeToken();

  // Fetch data from API
  useEffect(() => {
    setEnableCustom(user.user.role_user == "superadmin" ? true : false);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/component?link=/activity/${link}`);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [link]);

  // Conditional rendering
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {data && data.map((comp, index) => (
        <DynamicComponent key={index} component={comp} />
      ))}
    </div>
  );
};

export default Activity;