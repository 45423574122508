import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { confirmDelete } from '../../Activity/Helpers/PromptUtils';
import axios from 'axios';
import { formatCurrency } from '../../../helpers/CurrencyInput';
import DataTablePage from '../../../component/Table/DataTablePage';

const Product = () => {
    const navigate = useNavigate();

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Nama',
            accessor: 'name',
        },
        {
            Header: 'Category',
            accessor: 'category',
        },
        {
            Header: 'Harga',
            accessor: 'price',
            Cell: ({ value }) => formatCurrency(parseInt(value), 'IDR'),
        },
    ];

    const handleEdit = (rowData) => {
        navigate(`/edit-product/${rowData.id}`);
    };

    const handleRemove = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to delete this module?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/dashboard/base/product/delete',
                data: {
                    id: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    }

    const actions = [{
        child: <button className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1'>Hapus</button>,
        callback: (rowData) => handleRemove(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>Ubah</button>,
        callback: (rowData) => handleEdit(rowData)
    }];

    return (
        <div>
            <div className='flex justify-between'>
                <h1>Product</h1>
                <button
                    className="text-white text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 my-2"
                    type="button"
                    onClick={() => {
                        navigate('/add-product');
                    }}>
                    <FontAwesomeIcon icon={faAdd} color="white" /> Tambah
                </button>
            </div>
            <DataTablePage
                url={`${process.env.REACT_APP_API_URL}api/dashboard/base/product`}
                columns={columns}
                actions={actions}
            />            
        </div>
    );
};

export default Product;
