import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Shift({onChoose}) {
    
    const [loading, setLoading] = useState(true);

    const [shift, setShift] = useState([{
        id: '',
        id_project: '',
        shift: '',
        time_start: '',
        many_hours: '',
        hours_start_after: '',
        hours_start_before: '',
        hours_end_after: '',
        hours_end_before: '',
    }]);

    useEffect(() => {
        const fetchDataShift = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/attendance/shift?id_project=1`);
                setShift(response.data);
                console.log(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }
        
        fetchDataShift();
    }, []);

    const handleButtonClick = (data) => {
        onChoose(data);
    };

    if (loading) return <p>Loading...</p>;
    
    return (
        <div className='flex p-1'>
            {shift.map((data) => {
                return (
                    <div key={data.id} className="card border rounded-lg shadow-lg p-2 m-2 w-64">
                        <div className="card-content p-4">
                            <h2 className="text-xl font-bold mb-2">{data.shift}</h2>
                            <p className="text-gray-700 mb-4">{data.time_start}</p>
                            <button
                                type='button'
                                onClick={() => handleButtonClick(data)}
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            >
                                Pilih Shift
                            </button>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
