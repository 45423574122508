import React from 'react';

import { motion } from "framer-motion"
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('assets/bg-blurred-cheonjiyeon.png'); /* Add your image URL here */
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 0 20px;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 40px;
`;

const Button = styled(motion.button)`
  background: #61dafb;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #21a1f1;
  }
`;

const Home = () => {
  return (
    <Container>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Title>L`SUPERAPPS</Title>
        <Subtitle>IT & Apps Development</Subtitle>
        <p style={{ fontWeight: 'bold' }}>From Confusion to Certainty</p>
        <b style={{ color: '#f29f02' }}>Simplification Is the Key</b>
        <br />

        <Link to='/service'>
          <Button
            style={{ backgroundColor: '#f29f02' }}
            className='mt-4'
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Get Started
          </Button>
        </Link>

      </motion.div>
    </Container>
  );
};

export default Home;
