import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../../helpers/Jwt';

const EntryRequest = () => {
  // /edit-activity-request/:id_module_action/:id
  const { id, id_module_action } = useParams(); // Get 'id' from URL params
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [optionTable, setOptionTable] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const [optionProcedure, setOptionProcedure] = useState([]);
  const [loadingProcedure, setLoadingProcedure] = useState(true);

  const [optionColumn, setOptionColumn] = useState([]);
  const [loadingColumn, setLoadingColumn] = useState(true);

  const [queryShow, setQueryShow ] = useState("");

  const getProcedure = (id, key) => {
    const procedure = optionProcedure.find(data => data.id === id);
    return procedure ? procedure[key] : null;
  };

  const [layoutDataSelected, setLayoutData] = useState(null);

  const [initialValues, setInitialValues] = useState({
    method: '',
    url: '',
    description: '',
    table: '',
    column_data: '',
    return: '',
  });

  const [fields, setFields] = useState([{ id: 1, column: '', inputType: '', defaultValue: '' }]);
  
  // Fetch initial data based on 'id'
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (id) {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/system/request/detail?id=${id}`);
          const { url, method, description, table } = response.data.data;
          const { fields: fetchedFields } = response.data;

          setInitialValues(prevValues => ({
            ...prevValues,
            method: method || '',            
            url: url || '',            
            description: description || '', 
            table : table || ''
        }));          

          // Set dynamic fields from fetched data
          if (fetchedFields && fetchedFields.length > 0) {
            const mappedFields = fetchedFields.map((field, index) => ({
              id: index + 1,
              column: field.column || '',
              inputType: field.type || '',
              defaultValue: field.default || '',
            }));
            setFields(mappedFields);
          }          
        }
      } catch (error) {
        alert(error.response.data);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchOptionTabel();
    fetchOptionProcedure();
  }, [id]);

  const fetchOptionTabel = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/system/entity/optiontable`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }
        });
        setOptionTable(response.data);        
    } catch (error) {
        alert(error);
    } finally {
        setLoadingTable(false);
    }
  };

  const fetchOptionProcedure = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/system/procedure/optionprocedure`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }
        });
        setOptionProcedure(response.data);        
    } catch (error) {
        alert(error);
    } finally {
        setLoadingTable(false);
    }
  };

  // Function to add a new field
  const addField = (x) => {
    if (x == 'request') {
      const newFields = [...fields];
      newFields.push({ id: newFields.length + 1, inputType: '', defaultValue: '' });
      setFields(newFields);
    } 
  };

  // Function to remove a field
  const removeField = (x, id) => {
    if (x == 'request') {
      const newFields = fields.filter((field) => field.id !== id);
      setFields(newFields);
    } 
  };

  // Handle input change for dynamic fields
  const handleInputChange = (x, id, fieldKey, value) => {
    if (x == 'request') {
      const newFields = fields.map((field) =>
        field.id === id ? { ...field, [fieldKey]: value } : field
      );
      setFields(newFields);

    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    method: Yup.string().required('Method is required'),
    url: Yup.string().required('URL is required'),
    description: Yup.string().required('Description is required'),
  });

  // Handle form submission
  const onSubmit = async (values, requests) => {
    try {
      const formData = {
        id_project: '1',
        id_module_action,
        url: values.url,
        method: values.method,
        description: values.description,
        active: 'Y',
        fields: JSON.stringify(fields),
        table: values.table,
        column_data: values.column_data,
        return: values.return,
        field_data: values.field_data,
        procedure: getProcedure(values.procedure, 'name')
      };

      if (id) {
        formData['id'] = id;
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/request`, formData);
      } else {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/system/request`, formData);
      }

      navigate(-1);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, show alert or set form error state
    } finally {
      requests.setSubmitting(false);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="mx-auto p-4 bg-white shadow-md rounded">
      <h2 className="text-2xl font-bold mb-4">Entry Form Request</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className="gap-4 px-4 py-2">
              <label htmlFor="method">Method</label>
              <Field
                  as="select"
                  name="method"
                  className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                  onChange={(e) => {
                      setFieldValue(`method`, e.target.value);                    
                  }}>
                  <option>Pilih Metode Request</option>
                  <option value="GET">GET</option>
                  <option value="PUT">PUT</option>
                  <option value="POST">POST</option>
                  <option value="DELETE">DELETE</option>
              </Field>
              <ErrorMessage name="method" component="p" className="text-red-500 text-xs mt-1" />
            </div>
            <div className="gap-4 px-4 py-2">
                <label htmlFor="url">URL</label>
                <Field
                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                     name="url" />
                <ErrorMessage name="url" component="p" className='text-red-500 text-xs mt-1' />
            </div>
            <div className="gap-4 px-4 py-2">
                <label htmlFor="description">Deksripsi</label>
                <Field
                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                     name="description" />
                <ErrorMessage name="description" component="p" className='text-red-500 text-xs mt-1' />
            </div>

            <div className='flex'>
              <div className='flex-1 p-2'>
                <div className='flex justify-between my-2'>
                  <h1 className='p-2'>Request</h1>
                  <button
                    className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                    type="button"
                    onClick={() => addField('request')}>
                    <FontAwesomeIcon icon={faAdd} color="white" />
                  </button>
                </div>
                <hr />
                {fields.map((field) => (
                  <div key={field.id} className="flex items-center mb-2 flex-1">
                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Column"
                      type="text"
                      name={`column_${field.id}`}
                      value={field.column}
                      onChange={(e) => handleInputChange('request', field.id, 'column', e.target.value)}
                    />
                    <select
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      name={`input_type_${field.id}`}
                      value={field.inputType}
                      onChange={(e) => handleInputChange('request', field.id, 'inputType', e.target.value)}
                      defaultValue={"TEXT"}
                    >
                      <option value="TEXT">TEXT</option>
                      <option value="OBJECT">OBJECT</option>
                      <option value="ARRAY">ARRAY</option>
                    </select>

                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Default Value"
                      type="text"
                      name={`default_value_${field.id}`}
                      value={field.defaultValue}
                      onChange={(e) => handleInputChange('request', field.id, 'defaultValue', e.target.value)}
                    />

                    {/* Remove field button */}
                    <button
                      className="rounded focus:ring bg-red-500 hover:bg-red-700 p-2"
                      onClick={() => removeField('request', field.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} color="white" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className='flex-1 p-2'>
              <div className="gap-4 px-4 py-2">
                  <label htmlFor="type">Jenis Pengolahan Data</label>
                  <Field
                      as="select"
                      name="type"
                      className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      onChange={(e) => {
                          setLayoutData(e.target.value);                    
                      }}>
                      <option>Pilih Jenis</option>
                      <option key={"select"} value={"select"}>Select Data</option>
                      <option key={"procedure"} value={"procedure"}>Procedure</option>
                  </Field>                 
                </div>  

                {layoutDataSelected == "select" && (<div id='layout-select-data'>
                  <div className="gap-4 px-4 py-2">
                    <label htmlFor="table">Table</label>
                    <Field
                        as="select"
                        name="table"
                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        onChange={(e) => {
                            setFieldValue(`table`, e.target.value);                    
                        }}>
                        <option>Pilih Table</option>
                        {optionTable.map((table) => (
                            <option key={table.table} value={table.table}>
                                {table.name} ({table.table})
                            </option>
                        ))}
                    </Field>
                    <ErrorMessage name="table" component="p" className="text-red-500 text-xs mt-1" />
                  </div>
                  <div className="gap-4 px-4 py-2">
                    <label htmlFor="column_data">Select Column</label>
                    <Field
                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        name="column_data" defaultValue="*" />
                    <ErrorMessage name="column_data" component="p" className='text-red-500 text-xs mt-1' />
                  </div>
                  <div className="gap-4 px-4 py-2">
                    <label htmlFor="return">Return Data</label>
                    <Field
                        as="select"
                        name="return"
                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        onChange={(e) => {
                            setFieldValue(`return`, e.target.value);                    
                        }}>
                        <option>Pilih Return Data</option>
                        <option value={"ARRAY"}>Array</option>
                        <option value={"OBJECT"}>Object</option>
                    </Field>
                    <ErrorMessage name="return" component="p" className="text-red-500 text-xs mt-1" />
                  </div>
                </div>)}

                {layoutDataSelected == "procedure" && (<div id="layout-procedure-data">
                    <div className="gap-4 px-4 py-2">
                      <label htmlFor="procedure">Procedure</label>
                      <Field
                          as="select"
                          name="procedure"
                          className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                          onChange={(e) => {
                              setFieldValue(`procedure`, e.target.value);                 
                              setQueryShow(getProcedure(e.target.value, 'query'));
                          }}>
                          <option>Pilih Procedure</option>
                          {optionProcedure.map((dataProcedure) => (
                              <option key={dataProcedure.id} value={dataProcedure.id}>
                                  {dataProcedure.name} 
                              </option>
                          ))}
                      </Field>
                      <ErrorMessage name="table" component="p" className="text-red-500 text-xs mt-1" />
                    </div>
                    <div className="gap-4 px-4 py-2">
                      <label htmlFor="query">Query</label>
                      <Field
                        name="query"
                        placeholder="Query Procedure"
                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                        as="textarea"
                        rows={10}
                        value={queryShow}/>
                      <ErrorMessage name="query" component="p" className='text-red-500 text-xs mt-1' />
                    </div>
                    <div className="gap-4 px-4 py-2">
                      <label htmlFor="field_data">Field</label>
                      <Field
                          className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                          name="field_data" placeholder="Field Split By Comma(,)" />
                      <ErrorMessage name="field_data" component="p" className='text-red-500 text-xs mt-1' />
                    </div>
                </div>)}
                
            </div>

            <div className="m-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EntryRequest;
