import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utility function to format currency based on locale
export const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const numberString = amount.toString().replace(/[^,\d]/g, '');
    const split = numberString.split(',');
    const rest = split[0].length % 3;
    let formatted = split[0].substr(0, rest);
    const thousand = split[0].substr(rest).match(/\d{3}/g);
    
    if (thousand) {
        const separator = rest ? '.' : '';
        formatted += separator + thousand.join('.');
    }

    formatted = split[1] !== undefined ? formatted + ',' + split[1] : formatted;

    // Menambahkan simbol mata uang
    switch (currency) {
        case 'IDR':
            return `Rp ${formatted}`;
        case 'MYR':
            return `RM ${formatted}`;
        case 'USD':
            return `$ ${formatted}`;
        default:
            return formatted;
    }
    // if (value === undefined || value === null || isNaN(value)) return '';
    // let options = { style: 'currency', currency };
    // switch (currency) {
    //     case 'IDR':
    //         options = { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 };
    //         break;
    //     case 'MYR':
    //         options = { style: 'currency', currency: 'MYR', minimumFractionDigits: 2 };
    //         break;
    //     case 'USD':
    //         options = { style: 'currency', currency: 'USD', minimumFractionDigits: 2 };
    //         break;
    //     default:
    //         options = { style: 'currency', currency: 'USD', minimumFractionDigits: 2 };
    // }
    // return new Intl.NumberFormat('en-US', options).format(value);
};

// Fungsi untuk menghapus format mata uang dan mengembalikan nilai angka
const removeCurrency = (currency) => {
    return (amount) => {
        return amount.replace(/Rp|RM|\$/g, '').replace(/\./g, '').replace(',', '.');
    };
};

const CurrencyInput = ({ value, onChange, currency, placeholder, ...props }) => {
    const [inputValue, setInputValue] = useState(formatCurrency(value, currency));

    useEffect(() => {
        setInputValue(formatCurrency(value, currency));
    }, [value, currency]);

    const handleChange = (event) => {
        const rawValue = event.target.value;
        const numericValue = parseFloat(removeCurrency(currency)(rawValue)) || 0;
        setInputValue(formatCurrency(numericValue, currency));
        onChange(numericValue);
    };

    return (
        <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            placeholder={placeholder}
            {...props}
        />        
    );
};

CurrencyInput.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    currency: PropTypes.oneOf(['IDR', 'MYR', 'USD']).isRequired,
    placeholder: PropTypes.string
};

export default CurrencyInput;
