import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../helpers/Jwt';

const EntryOutlet = () => {
    const { project, id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({
        name: '',
        address: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (id) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/core/outlet/outlet/detail?id=${id}`, {
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${getToken()}`
                        }
                    });
                    const { name, address } = response.data;
                    setInitialValues({
                        name: name || '',
                        address: address || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error if needed (e.g., show a notification)
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters long'),
        address: Yup.string()
            .required('Address is required'),
    });

    // Handle form submission
    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = {
                ...values,
                id_project: project,
                id: id || undefined
            };

            await axios.post(`${process.env.REACT_APP_API_URL}api/core/outlet/outlet`, formData, {
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            navigate(-1); // Navigate back after submission
        } catch (error) {
            alert(error.response.data)
        } finally {
            setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Project</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-4 px-2">
                            <label htmlFor="name">Name</label>
                            <Field
                                name="name"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mb-4 px-2">
                            <label htmlFor="address">Alamat</label>
                            <Field
                                name="address"
                                className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text" />
                            <ErrorMessage name="address" component="p" className='text-red-500 text-xs mt-1' />
                        </div>                        
                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryOutlet;
