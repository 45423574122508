import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';
import Shift from './component/Shift';

const EntrySchedule = () => {
    const { id_hs_employee } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [isTypeScheduleAccumulated, setTypeScheduleAccumulated] = useState(false);

    const [initialValues, setInitialValues] = useState({
        id: '',
        id_shift: '',
        id_hs_employee: '',
        employee_name: '',
        type_schedule: 'SCHEDULED_DAY',
        shift: '',
        time_start: '',
        start_date: '',
        end_date: ''
    });

    useEffect(() => {
        const fetchEmployee = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/attendance/employee/detail?id=${id_hs_employee}`);
                const {data} = response;
                setInitialValues(prevValues => ({
                    ...prevValues,
                    id_shift: data.schedule?.id_hs_attendance_shift,
                    id_hs_employee: data.id,
                    employee_name: data.name,
                    type_schedule: data.schedule?.type_schedule,
                    shift: data.schedule?.shift,
                    time_start: data.schedule?.time_start,
                    start_date: data.schedule?.start_date,
                    end_date: data.schedule?.end_date
                }));

                if (data.schedule?.type_schedule == 'ACCUMULATED_DAY') {
                    setTypeScheduleAccumulated(true);
                }                
            } catch (error) {
                console.error('Error fetching employee data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployee();

    }, [id_hs_employee]);

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        shift: Yup.string().required('Shift is required'),
        // id_user: Yup.string().required('Id User is required'),
        time_start: Yup.string().required('Time Start is required'),
        // day: Yup.string().required('Day is required'),
    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: '1',
                id_shift: values.id_shift,
                id_hs_employee: values.id_hs_employee,
                type_schedule: values.type_schedule,
                shift: values.shift,
                time_start: values.time_start,
                start_date: values.start_date,
                end_date: values.end_date
            };

            
            await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/schedule`, formData);
            navigate(-1);
        } catch (error) {
            alert(error.response.data)
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    const handleTypeScheduleChange = (event, setFieldValue) => {
        const selectedValue = event.target.value;
        setFieldValue("type_schedule", selectedValue);

        if (selectedValue == "ACCUMULATED_DAY") {
            setTypeScheduleAccumulated(true);
        } else {
            setTypeScheduleAccumulated(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Module</h2>

            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="flex flex-col md:flex-row gap-4 p-4">
                            <div className='flex-1'>
                                <Field
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="hidden" name="id_shift" />

                                <Field
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="hidden" name="id_hs_employee" />


                                <div className="mb-4">
                                    <label htmlFor="employee_name">Pegawai</label>
                                    <Field
                                        disabled
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="text" name="employee_name" />
                                    <ErrorMessage name="employee_name" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="name">Shift</label>
                                    <Field
                                        disabled
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="text" name="shift" />
                                    <ErrorMessage name="shift" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="time_start">Jam Masuk</label>
                                    <Field
                                        disabled
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="time" name="time_start" />
                                    <ErrorMessage name="time_start" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="day">Pilih Jenis Jadwal</label>
                                    <Field
                                        name="type_schedule"
                                        as="select"
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        onChange={(event) => handleTypeScheduleChange(event, setFieldValue)}>
                                        <option key={"SCHEDULED_DAY"} value={"SCHEDULED_DAY"}>Kontrak</option>
                                        <option key={"ACCUMULATED_DAY"} value={"ACCUMULATED_DAY"}>Akumulasi Kehadiran/Hari</option>
                                    </Field>
                                    <ErrorMessage name="type_schedule" component="p" className="text-red-500" />
                                </div>
                                <div className="flex mb-4">
                                    <div className='flex-1 pr-2'>
                                        <label htmlFor="start_date">Tanggal Mulai</label>
                                        <Field
                                            disabled={isTypeScheduleAccumulated}
                                            className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            type="date" name="start_date" />
                                        <ErrorMessage name="start_date" component="p" className='text-red-500 text-xs mt-1' />
                                    </div>
                                    <div className='flex-1 pl-2'>
                                        <label htmlFor="end_date">Tanggal Selesai</label>
                                        <Field
                                            disabled={isTypeScheduleAccumulated}
                                            className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            type="date" name="end_date" />
                                        <ErrorMessage name="end_date" component="p" className='text-red-500 text-xs mt-1' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* shift */}
                        <Shift onChoose={(data) => {
                            setFieldValue('id_shift', data.id)
                            setFieldValue('shift', data.shift)
                            setFieldValue('time_start', data.time_start)
                        }} />

                        <div className="m-4 text-right">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntrySchedule;
