import React, { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../../component/Table/DataTable';
import { confirmDelete } from '../../../helpers/PromptUtils';
import { getToken } from '../../../helpers/Jwt';

const Shift = () => {
    const navigate = useNavigate();
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [projectID, selectProjectID] = useState(null);
    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);
    
    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Shift',
            accessor: 'shift',
        },
        {
            Header: 'Time Start',
            accessor: 'time_start',
        },
        {
            Header: 'Many Hours',
            Cell: ({ row }) => (
                <div>
                    {row.original.many_hours} Jam
                </div>
            ),
        },
    ];

    // Fetch data from API
    useEffect(() => {
        const fetchOptionProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                setOptionProject(response.data);                
            } catch (error) {
                alert(error);
            } finally {
                setLoadingProject(false);
            }
        };

        fetchOptionProject();
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}api/core/attendance/shift?id_project=${projectID}`)
            .then(response => response.json())
            .then(data => {
                setData(data); // Set fetched data
                setLoading(false); // Data fetching complete
            })
            .catch(error => {
                setError(error); // Handle errors
                setLoading(false); // Data fetching complete
            });
    }, [projectID]);

    const handleEdit = (rowData) => {
        navigate(`/edit-attendance-shift/${rowData.id}`);
    };

    const handleRemove = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to delete this module?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/core/attendance/shift/delete',
                data: {
                    id: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    }

    const actions = [{
        child: <button className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faTrash} color="white" />
        </button>,
        callback: (rowData) => handleRemove(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faEdit} color="white" />
        </button>,
        callback: (rowData) => handleEdit(rowData)
    }];

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className=''>
            <div className='flex items-center justify-between px-5'>
                <h1>Shift</h1>

                <div className='flex items-center'>
                    {loadingProject ? (
                        <h1>Loading...</h1>
                    ) : (
                        <Formik>
                            <Field
                                as="select"
                                name="id_project"
                                className="my-1 p-2 block border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                onChange={(e) => {
                                    selectProjectID(e.target.value);
                                }}
                            >
                                <option value="" label="Pilih Project" />
                                {optionProject.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.name}
                                    </option>
                                ))}
                            </Field>
                        </Formik>
                    )}

                    <button
                        className="text-white text-sm rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 my-2 ml-2"
                        type="button"
                        onClick={() => {
                            navigate('/add-attendance-shift');
                        }}
                    >
                        <FontAwesomeIcon icon={faAdd} color="white" /> Tambah
                    </button>
                </div>
            </div>

            {loading ? (
                <h1>Loading...</h1>
            ) : (
                <DataTable
                    columns={columns}
                    data={data}
                    actions={actions}
                />)}
        </div>
    );
};

export default Shift;
