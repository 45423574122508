import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Autocomplete from 'react-autocomplete';

const EntryOvertime = () => {
    const { id, id_attendance } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    
    const [initialValues, setInitialValues] = useState({
        id: '',
        id_attendance: id_attendance,
        hours_overtime : '',
        shift: '',
        time_start: '',
        time_end: '',
    });

    useEffect(() => {
        const fetchDataSchedule = async () => {
            setLoading(true);
            try {
                if (id_attendance) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/attendance/attendance/detail?id=${id_attendance}`);
                    const { shift, time_start, time_end } = response.data;
                    setInitialValues(prevValues => ({
                        ...prevValues,
                        shift: shift || '',
                        time_start: time_start || '',
                        time_end: time_end || '',
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // if (id) {
                //     fetchData();                    
                // } else {
                    setLoading(false);
                // }
            }
        };

        const fetchData = async () => {
            // setLoading(true);
            // try {
            //     if (id) {
            //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/attendance/overtime/detail?id=${id_schedule}`);
            //         const { hours_overtime } = response.data;
            //         setInitialValues(prevValues => ({
            //             ...prevValues,
            //             hours_overtime: hours_overtime || ''
            //         }));
            //     }
            // } catch (error) {
            //     console.error('Error fetching data:', error);
            // } finally {
            //     setLoading(false);
            // }
        };

        fetchDataSchedule();
    }, [id_attendance, id]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         setLoading(true);
    //         try {
    //             if (id) {
    //                 const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/attendance/shift/detail?id=${id}`);
    //                 const { shift, day_start, time_start, day_end, time_end } = response.data;
    //                 setInitialValues(prevValues => ({
    //                     ...prevValues,
    //                     id_shift: shift.id,
    //                     shift: shift.shift,
    //                     time_start: shift.time_start
    //                 }));
    //                 // setInitialValues({
    //                 //     id: id,
    //                 //     id_shift: shift || '',
    //                 //     id_hs_employee: day_start || '',
    //                 //     shift: time_start || '',
    //                 //     time_start: day_end || '',
    //                 //     day: time_end || '',
    //                 // });
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [id]);

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        shift: Yup.string().required('Shift is required'),
        // id_user: Yup.string().required('Id User is required'),
        // time_start: Yup.string().required('Time Start is required'),
        // day: Yup.string().required('Day is required'),
    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: '1',
                id_attendance: initialValues.id_attendance,
                hours_overtime: values.hours_overtime
            };
            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/overtime`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/overtime`, formData);
            }
            navigate(-1);
        } catch (error) {
            alert(error.response.data)
            console.error('Error submitting form:', error);
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Overtime</h2>
            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="flex flex-col md:flex-row gap-4 p-4">
                            <div className='flex-1'>
                                <Field
                                    className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="hidden" name="id_shift" />
                                <div className="mb-4">
                                    <label htmlFor="name">Shift</label>
                                    <Field
                                        disabled
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="text" name="shift" />
                                    <ErrorMessage name="shift" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                <div className="mb-4 flex">
                                    <div className='flex-1 p-2'>
                                        <label htmlFor="time_start">Jam Masuk</label>
                                        <Field
                                            disabled
                                            className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            type="time" name="time_start" />
                                        <ErrorMessage name="time_start" component="p" className='text-red-500 text-xs mt-1' />
                                    </div>
                                </div>
                                <div className="mb-4 flex">
                                    <div className='flex-1 p-2'>
                                        <label htmlFor="time_end">Jam Keluar</label>
                                        <Field
                                            disabled
                                            className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            type="time" name="time_end" />
                                        <ErrorMessage name="time_end" component="p" className='text-red-500 text-xs mt-1' />
                                    </div>                
                                </div>       
                                <div className="mb-4 flex">
                                    <div className='flex-1 p-2'>
                                        <label htmlFor="hours_overtime">Jam Lembur</label>
                                        <Field
                                            max={4}
                                            className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            type="number" name="hours_overtime" />
                                        <ErrorMessage name="hours_overtime" component="p" className='text-red-500 text-xs mt-1' />
                                    </div>
                                </div>                                 
                            </div>
                        </div>

                        {/* shift */}
                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryOvertime;
