import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen,  faChartGantt, faComputerMouse, faBank, faSchool, faStore, faCalculator, faDownload} from '@fortawesome/free-solid-svg-icons';
import FloatingButton from './component/FloatingButton';

const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
`;

const Header = styled.header`
    text-align: center;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    color: #000;
    font-size: 2.5rem;
`;

const Description = styled.p`
    font-size: 1.2rem;
`;

const ServiceGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
`;

const ServiceCard = styled.div`
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s;

    &:hover {
        transform: translateY(-5px);
    }
`;

const ServiceTitle = styled.h2`
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
    margin-bottom: 20px;
`;

const Button = styled.button`
    background: #000;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: #357ab8;
    }
`;

const Catalog = () => {
    const services = [
        { title: 'Kelola Dokumen dengan Mudah', description: '', icon: faFolderOpen },
        { title: 'Sistem Manajemen Produktivitas', description: '', icon: faChartGantt },
        { title: 'Sistem Manajemen Perkantoran', description: '', icon: faComputerMouse },
        { title: 'Penerapan Aplikasi dalam Sistem Perbankan SYariah (BMT)', description: '', icon: faBank },
        { title: 'Penerapan Aplikasi dalam Sektor Pendidikan', description: '', icon: faSchool },
        { title: 'Penerapan Aplikasi dalam Jasa Travel Umroh', description: '', icon: faFolderOpen },
        { title: 'Sistem Manajemen Restoran', description: '', icon: faStore },
        { title: 'Aplikasi Buku besar Aktiva - pasiva', description: '', icon: faCalculator },
        // { title: 'Service 4', description: 'Description of service 4.' },
    ];

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/assets/EBOOK-LSUPERAPPS.pdf'; // Replace with your file path
        link.download = 'EBOOK-LSUPERAPPS.pdf'; // Set the name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Container>
            <Header>
                <Title>Overview of Our Solutions</Title>
                <Description>Insights into how our solutions can address specific business challenges.</Description>
            </Header>
            <ServiceGrid>
                {services.map((service, index) => (
                    <ServiceCard key={index}>
                        <ServiceTitle>
                            <FontAwesomeIcon icon={service.icon} style={{ marginRight: '10px' }} />
                            {service.title}
                        </ServiceTitle>
                        <ServiceDescription>{service.description}</ServiceDescription>
                        {/* <Button>Learn More</Button> */}
                    </ServiceCard>
                ))}
            </ServiceGrid>

            <FloatingButton icon={<FontAwesomeIcon icon={faDownload} />} onClick={handleDownload} />
        </Container>
    );
};

export default Catalog;
