import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { getToken } from '../../../helpers/Jwt';

const EntryActionCompnent = () => {
  const { id_module, id_parent, id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [optionComponent, setOptionComponent] = useState([]);
  const [loadingComponent, setLoadingComponent] = useState(true);

  const [optionAttribute, setOptionAttribute] = useState([]);
  const [loadingAttribute, setLoadingAttribute] = useState(true);

  const [initialValues, setInitialValues] = useState({
    type: 'ACTION',
    id_parent: '',
    id_module: '',
    element: '',
  });

  // State for dynamic fields
  const [attributes, setAttributes] = useState([{ id: 1, key: '', value: '' }]);
  const [columns, setColumns] = useState([{ id: 1, key: '', value: '' }]);

  const fetchOptionComponent = async () => {
    setLoadingComponent(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/system/component`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        }
      });
      setOptionComponent(response.data);
    } catch (error) {
      alert(error);
    } finally {
      setLoadingComponent(false);
    }
  };

  const fetchOptionAttribute = async (id_component) => {
    setLoadingAttribute(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/system/component/attribute?id_md_component=${id_component}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getToken()}`
        }
      });
      setOptionAttribute(response.data);
    } catch (error) {
      alert(error);
    } finally {
      setLoadingAttribute(false);
    }
  };

  const getElementComponent = (id_component) => {
    const foundComponent = optionComponent.find(data => data.id === id_component);
    return foundComponent ? foundComponent.element.toLowerCase() : null; // Return the element or null if not found
  };


  const addField = (x) => {
    if (x == 'attribute') {
      const newAttributes = [...attributes];
      newAttributes.push({ id: newAttributes.length + 1, key: '', value: '' });
      setAttributes(newAttributes);
    } else if (x == 'column') {
      const newColumns = [...columns];
      newColumns.push({ id: newColumns.length + 1, key: '', value: '' });
      setColumns(newColumns);
    }
  };

  // Function to remove a field
  const removeField = (x, id) => {
    if (x == 'attribute') {
      const newAttributes = attributes.filter((field) => field.id !== id);
      setAttributes(newAttributes);
    } else if (x == 'column') {
      const newColumns = columns.filter((field) => field.id !== id);
      setColumns(newColumns);
    }
  };

  // Handle input change for dynamic fields
  const handleInputChange = (x, id, fieldKey, value) => {
    if (x == 'attribute') {
      const newAttributes = attributes.map((field) =>
        field.id === id ? { ...field, [fieldKey]: value } : field
      );
      setAttributes(newAttributes);

    } else if (x == 'column') {
      const newColumns = columns.map((field) =>
        field.id === id ? { ...field, [fieldKey]: value } : field
      );
      setColumns(newColumns);
    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    element: Yup.string().required('Bidang pilihan Element wajib dipilih')
  });

  useEffect(() => {
    fetchOptionComponent();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (id) {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/dashboard/component/detail?id=${id}`);
          const { type, id_parent, id_module, element, id_md_component } = response.data.data;
          const { attributes: fetchedAttributes } = response.data;
          const { columns: fetchedColumns } = response.data;

          setInitialValues(prevValues => ({
            ...prevValues,
            type: type,
            id_parent: id_parent,
            id_module: id_module,
            element: id_md_component,
          }));

          if (fetchedAttributes && fetchedAttributes.length > 0) {
            const mappedAttributes = fetchedAttributes.map((field, index) => ({
              id: index + 1,
              key: field.key || '',
              value: field.value || '',
            }));
            setAttributes(mappedAttributes);
          }

          if (fetchedColumns && fetchedColumns.length > 0) {
            const mappedColumns = fetchedColumns.map((field, index) => ({
              id: index + 1,
              key: field.key || '',
              value: field.value || '',
            }));
            setColumns(mappedColumns);
          }
        }
      } catch (error) {
        alert(error.response.data);
      } finally {
        setLoading(false);
      }
    };


    fetchData()
  }, [id]);

  // Handle form submission
  const onSubmit = async (values, requests) => {
    try {
      const formData = {
        id_project: '1',
        id_module: id_module,
        id_parent: id_parent,
        type: 'ACTION',
        element: getElementComponent(values.element),
        active: 'Y',
        attributes: JSON.stringify(attributes),
        columns: JSON.stringify(columns),
      };

      if (id) formData['id'] = id;
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/dashboard/component`, formData);
      navigate(-1);
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, show alert or set form error state
    } finally {
      requests.setSubmitting(false);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="mx-auto p-4 bg-white shadow-md rounded">
      <h2 className="text-2xl font-bold mb-4">Entry Component</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            {/* Form fields */}
            <div className='flex-1 px-2'>
              <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                Project
              </label>
              {loadingComponent ? (
                <h1>Loading...</h1>
              ) : (
                <>
                  <Field
                    as="select"
                    name={`element`}
                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                    onChange={(e) => {
                      setFieldValue("element", e.target.value);
                      fetchOptionAttribute(e.target.value)
                    }}>
                    <option value="">Pilih Atribut </option>
                    {optionComponent.map((component) => (
                      <option key={component.id} value={component.id}>
                        {component.element}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="element" component="p" className="text-red-500 text-xs mt-1" />
                </>
              )}
            </div>

            {/* Dynamic fields */}
            <div className='flex'>
              <div className='flex-1 p-2'>
                <div className='flex justify-between my-2'>
                  <h1 className='p-2'>Attribute</h1>
                  <button
                    className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                    type="button"
                    onClick={() => addField('attribute')}>
                    <FontAwesomeIcon icon={faAdd} color="white" />
                  </button>
                </div>
                <hr />
                {attributes.map((field, index) => (
                  <div key={field.id} className="flex items-center mb-2 flex-1">
                    <Field
                      as="select"
                      name={`column_${field.id}`}
                      className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      onChange={(e) => handleInputChange('attribute', field.id, 'key', e.target.value)}>
                      <option value="">Pilih Atribut </option>
                      {optionAttribute.map((attribute) => (
                        <option key={attribute.value} value={attribute.key}>
                          {attribute.key}
                        </option>
                      ))}
                    </Field>
                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Default Value"
                      type="text"
                      name={`default_value_${field.id}`}
                      defaultValue={field.value}
                      onChange={(e) => handleInputChange('attribute', field.id, 'value', e.target.value)}
                    />

                    <button
                      className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 m-1"
                      onClick={() => removeField('attribute', field.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} color="white" />
                    </button>
                  </div>
                ))}
              </div>

              <div className='flex-1 p-2'>
                <div className='flex justify-between my-2'>
                  <h1 className='p-2'>Style</h1>
                  <button
                    className="text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 m-1"
                    type="button"
                    onClick={() => addField('column')}>
                    <FontAwesomeIcon icon={faAdd} color="white" />
                  </button>
                </div>
                <hr />
                {columns.map((field, index) => (
                  <div key={field.id} className="flex items-center mb-2 flex-1">
                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Column"
                      type="text"
                      name={`column_${field.id}`}
                      defaultValue={field.key}
                      onChange={(e) => handleInputChange('column', field.id, 'key', e.target.value)}
                    />
                    <input
                      className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                      placeholder="Default Value"
                      type="text"
                      name={`default_value_${field.id}`}
                      defaultValue={field.value}
                      onChange={(e) => handleInputChange('column', field.id, 'value', e.target.value)}
                    />
                    <button
                      className="text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 m-1"
                      onClick={() => removeField('column', field.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} color="white" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Submit button */}
            <div className="mt-4">
              <button
                type="submit"
                disabled={isSubmitting}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EntryActionCompnent;
