// Modal.js
import { Formik, Form, Field, ErrorMessage } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ title, fields, closeModal, submitModal }) => {

    const validationSchema = Yup.object().shape({
        ...fields.reduce((acc, field) => {
            acc[field.column] = Yup.string().required(`${field.column} is required`);
            return acc;
        }, {})
    });

    const initialValues = fields.reduce((acc, field) => {
        acc[field.column] = field.value || ''; 
        return acc;
    }, {});

    return (
        <div style={modalStyles}>
            <div style={modalContentStyles}>
                <button onClick={closeModal} style={closeButtonStyles}>
                    <FontAwesomeIcon icon={faClose} color="black" />
                </button>
                <h2>{title}</h2>
                <hr className='my-3' />
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitModal}
                >
                    {({ handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                            {fields.map((field) => (
                                <div key={field.id} className="mb-2">
                                    <label htmlFor={field.column} className="block font-semibold">{field.column}</label>
                                    <Field
                                        as="input"
                                        type={field.input_type}
                                        name={field.column}
                                        placeholder={field.column}
                                        className="m-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    />
                                    <ErrorMessage
                                        name={field.column}
                                        component="p"
                                        className="text-red-500 text-xs mt-1"
                                    />
                                </div>
                            ))}

                            <div className="mt-4">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

// Styles
const modalStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
};

const modalContentStyles = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    width: '500px',
    position: 'relative'
};

const closeButtonStyles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: 'none',
    background: 'none',
    fontSize: '18px',
    cursor: 'pointer'
};

export default Modal;
