// FloatingButton.js
import React from 'react';
import styled from 'styled-components';

const Button = styled.a`
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #f29f02;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background 0.3s;

    &:hover {
        background: #357ab8;
    }
`;

const FloatingButton = ({ icon, onClick, href }) => {
    return (
        <Button onClick={onClick} href={href}>
            {icon}
        </Button>
    );
};

export default FloatingButton;
