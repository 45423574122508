import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthContext';

const HeaderContainer = styled.header`
    width: 100%;
    background: #20232a;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed; // Ensure absolute elements are positioned correctly
`;

const Logo = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
`;

const Nav = styled.nav`
    display: flex;
    gap: 20px;
    position: relative;

    @media (max-width: 768px) {
        display: none; // Hide the default nav in mobile view
    }
`;

const NavLink = styled(Link)`
    color: white;
    text-decoration: none;
    font-size: 1rem;
    position: relative;

    &:hover {
        text-decoration: underline;
    }
`;

const Submenu = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    top: 50%;
    left: 50%;
    background: #333;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SubmenuLink = styled(Link)`
    display: block;
    color: white;
    text-decoration: none;
    padding: 5px 10px;

    &:hover {
        background: #444;
    }
`;

const MenuToggle = styled.button`
    display: none;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1000; // Ensure it appears above other elements

    @media (max-width: 768px) {
        display: block; // Show the menu toggle button in mobile view
    }
`;

const MobileNav = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    background: #20232a;
    position: absolute;
    top: 60px; // Adjust based on header height
    left: 0;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid #444;
    z-index: 1000; // Ensure it appears above other elements

    @media (min-width: 768px) {
        display: none; // Hide in larger screens
    }

    a {
        display: block;
        padding: 10px 20px;
        color: white;
        text-decoration: none;
        font-size: 1rem;

        &:hover {
            background: #444;
        }
    }
`;


const Header = () => {
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [privacySubmenuOpen, setPrivacySubmenuOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { auth } = useAuth();

    const handleMouseEnter = () => setSubmenuOpen(true);
    const handleMouseLeave = () => setSubmenuOpen(false);
    const handlePrivacyMouseEnter = () => setPrivacySubmenuOpen(true);
    const handlePrivacyMouseLeave = () => setPrivacySubmenuOpen(false);

    if (auth.isAuthenticated) return null; // Do not render the header if not authenticated

    return (
        <HeaderContainer>
            <Logo className='flex'>
                <img src={`/assets/icon_network.png`} alt="Logo" className="w-10 h-auto mr-3" />
                L`SuperApps
            </Logo>
            <Nav>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/service">Service</NavLink>
                <NavLink to="/catalog">Catalog</NavLink>
                <NavLink
                    to="#"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    About
                    <Submenu isOpen={submenuOpen}>
                        {/* <SubmenuLink to="/about/team">Team</SubmenuLink> */}
                        <SubmenuLink to="/about/company">Company</SubmenuLink>
                        {/* <SubmenuLink to="/about/careers">Careers</SubmenuLink> */}
                    </Submenu>
                </NavLink>
                {/* <NavLink to="/contact">Contact</NavLink> */}
                {/* <NavLink
                    to="#"
                    onMouseEnter={handlePrivacyMouseEnter}
                    onMouseLeave={handlePrivacyMouseLeave}
                >
                    Privacy Policy
                    <Submenu isOpen={privacySubmenuOpen}>
                        <SubmenuLink to="/privacy-policy/buffet-ahmad-salim">Buffet Ahmad Salim</SubmenuLink>
                    </Submenu>
                </NavLink> */}
                {/* <NavLink to="/admin-login">Login</NavLink> */}
            </Nav>
            <MenuToggle onClick={() => setMobileMenuOpen(prev => !prev)}>
                {mobileMenuOpen ? '✖' : '☰'}
            </MenuToggle>
            <MobileNav isOpen={mobileMenuOpen}>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/service">Service</NavLink>
                <NavLink to="/catalog">Catalog</NavLink>
                <NavLink
                    to="#"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    About
                    <Submenu isOpen={submenuOpen}>
                        {/* <SubmenuLink to="/about/team">Team</SubmenuLink> */}
                        <SubmenuLink to="/about/company">Company</SubmenuLink>                        
                    </Submenu>
                </NavLink>
                {/* <NavLink to="/contact">Contact</NavLink> */}
                {/* <NavLink
                    to="#"
                    onMouseEnter={handlePrivacyMouseEnter}
                    onMouseLeave={handlePrivacyMouseLeave}
                >
                    Privacy Policy
                    <Submenu isOpen={privacySubmenuOpen}>
                        <SubmenuLink to="/privacy-policy/buffet-ahmad-salim">Buffet Ahmad Salim</SubmenuLink>
                    </Submenu>
                </NavLink> */}
                {/* <NavLink to="/admin-login">Login</NavLink> */}
            </MobileNav>
        </HeaderContainer>
    );
};

export default Header;
