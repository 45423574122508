import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyInput from '../../../helpers/CurrencyInput';
import CustomInput from '../../../helpers/CustomInput';

const EntryProduct = () => {
    const { id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        category: '',
        description: '',
        price: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (id) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/dashboard/base/product/detail?id=${id}`);

                    const { name, category, description, price } = response.data;

                    setInitialValues({
                        id: id,
                        name: name || '',
                        category: category || '',
                        description: description || '',
                        price: price || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        category: Yup.string().required('Category is required'),
        description: Yup.string().required('Description is required'),
        price: Yup.string().required('Price is required')
    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: '1',
                name: values.name,
                category: values.category,
                description: values.description,
                price: values.price,
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/dashboard/base/product`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/dashboard/base/product`, formData);
            }

            // console.log(values);
            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    const calculateSalaryDay = (salary, main_days) => {
        if (salary && main_days) {
            return parseInt(salary / main_days)
        }
        return 0;
    }

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Product</h2>
            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, getFieldProps }) => (
                    <Form onSubmit={handleSubmit}>
                        {/* Form fields */}
                        <div className="flex flex-col md:flex-row gap-4 p-4">
                            <div className='flex-1'>
                                <div className="mb-4 px-2">
                                    <label htmlFor="name">Name</label>
                                    <Field
                                        name="name"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="text" />
                                    <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                <div className="mb-4 px-2">
                                    <label htmlFor="category">Category</label>
                                    <Field
                                        name="category"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="text" />
                                    <ErrorMessage name="category" component="p" className='text-red-500 text-xs mt-1' />
                                </div>   
                                <div className="mb-4 px-2">
                                    <label htmlFor="description">Decription</label>
                                    <Field
                                        name="description"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        type="text" />
                                    <ErrorMessage name="description" component="p" className='text-red-500 text-xs mt-1' />
                                </div>                                
                                <div className="mb-4 px-2">
                                    <label htmlFor="price">Price</label>
                                    <CurrencyInput
                                        id="price"
                                        name="price"
                                        value={values.price}
                                        onChange={(value) => setFieldValue('price', value)}
                                        currency="IDR"
                                        placeholder="Masukkan Harga"
                                        className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    />
                                    <ErrorMessage name="salary" component="p" className='text-red-500 text-xs mt-1' />
                                </div>
                                

                            </div>
                        </div>

                        {/* shift */}
                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryProduct;
