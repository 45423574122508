import React, { useState, useEffect } from 'react';
import DataTable from '../../component/Table/DataTable';
import { Field, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { confirmDelete } from '../Activity/Helpers/PromptUtils';
import axios from 'axios';
import { getToken } from '../../helpers/Jwt'

const Entity = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [projectID, selectProjectID] = useState(null);
    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);    

    const [responseMessage, setResponseMessage] = useState(null);

    // Define columns
    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Nama',
            accessor: 'name',
        },
        {
            Header: 'Table',
            accessor: 'table',
        },
    ];

    // Fetch data from API
    useEffect(() => {
        const fetchOptionProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                setOptionProject(response.data);                
            } catch (error) {
                alert(error);
            } finally {
                setLoadingProject(false);
            }
        };

        fetchOptionProject();
    }, []);
    

    // Fetch data from API
    useEffect(() => {
        if (projectID) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_URL}api/system/entity?id_project=${projectID}`,
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            })
            .then(response => {
                setData(response.data.data); // Set fetched data
                setLoading(false); // Data fetching complete
            })
            .catch(error => {
                setError(error); // Handle errors
                setLoading(false); // Data fetching complete
            });        
            
        }

    }, [projectID]);

    const handleEdit = (rowData) => {
        navigate(`/edit-entity/${rowData.id}`);
    };

    const handleRemove = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to delete this entity?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/system/entity/delete',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                data: {
                    id: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    }

    const handleExecute = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to execute this entity?');
        if (confirmed) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_BASE_URL}api/system/entity/execute?id=${rowData.id}`,
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }).then(function (response) {
                showResponse(response.data);
                // window.location.reload();
            });            
        }
    }

    const showResponse = (response) => {
        setResponseMessage(response);
    };

    const renderResponse = () => {
        if (!responseMessage) return null;

        return (
            <div className="p-4">
                <h2 className="text-lg font-bold">Action: {responseMessage.action.name}</h2>
                <div className="mt-4">
                    {responseMessage.action.detail.map((item, index) => (
                        <div key={index} className={`p-2 mb-2 border rounded-md ${item.status ? 'border-green-500 bg-green-100' : 'border-red-500 bg-red-100'}`}>
                            <p className="font-semibold">{item.status ? 'Success' : 'Error'}</p>
                            <p className="text-sm">{item.message}</p>
                            <p className="text-xs text-gray-500">Time taken: {item.time.toFixed(4)} seconds</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const actions = [{
        child: <button className='text-white text-xs rounded focus:ring bg-gray-500 hover:bg-gray-700 p-2 mx-1'>Eksekusi</button>,
        callback: (rowData) => handleExecute(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1'>Hapus</button>,
        callback: (rowData) => handleRemove(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>Ubah</button>,
        callback: (rowData) => handleEdit(rowData)
    }];

    if (error) {
        return <div>Error: {error.message}</div>;
    }    

    return (
        <div>
            <div className='flex items-center justify-between px-5'>
                <h1>Entity</h1>
                
                <div className='flex items-center'>
                    {loadingProject ? (
                        <h1>Loading...</h1>
                    ) : (
                        <Formik>
                            <Field
                                as="select"
                                name="id_project"
                                className="my-1 p-2 block border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                onChange={(e) => {
                                    selectProjectID(e.target.value);
                                }}
                            >
                                <option value="" label="Pilih Project" />
                                {optionProject.map((project) => (
                                    <option key={project.id} value={project.id}>
                                        {project.name}
                                    </option>
                                ))}
                            </Field>
                        </Formik>
                    )}

                    <button
                        className="text-white text-sm rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 my-2 ml-2"
                        type="button"
                        onClick={() => {
                            navigate('/add-entity');
                        }}
                    >
                        <FontAwesomeIcon icon={faAdd} color="white" /> Tambah
                    </button>
                </div>
            </div>

            {loading ? (
                <center><h1>Loading...</h1></center>
            ) : (
                <DataTable
                    columns={columns}
                    data={data}
                    actions={actions}
                />)}

            {renderResponse()} {/* Call to render the response messages */}
        </div>
    );
};

export default Entity;
