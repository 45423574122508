import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../../component/Table/DataTable';
import { confirmDelete } from '../../../helpers/PromptUtils';
import DataTablePage from '../../../component/Table/DataTablePage';

const Attendance = () => {
    const { id_hs_employee } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();

    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Tanggal',
            accessor: 'date',
        },
        {
            Header: 'Check In (Masuk)',
            Cell: ({ row }) => (
                <div>
                    <p>{row.original.check_in ?? "-"}</p>
                    <b>{row.original.note_check_in}</b>
                </div>
            ),
        },
        {
            Header: 'Check Out (Pulang)',
            Cell: ({ row }) => (
                <div>
                    <p>{row.original.check_out ?? "-"}</p>
                    <b>{row.original.note_check_out}</b>
                </div>
            ),
        },       
    ];

    const handleOvertime = (rowData) => {
        if (rowData.overtime != null) {
            navigate(`/edit-attendance-overtime/${rowData.id}/${rowData.overtime.id}`);
        } else {
            navigate(`/add-attendance-overtime/${rowData.id}`);
        }
    };

    const handleRemove = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to delete this module?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/core/attendance/schedule/delete',
                data: {
                    id: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });
        }
    }

    const handleGenerate = () => {
        const confirmed = confirmDelete('Are you sure you want to re-generate this schedule?');
        if (confirmed) {
            try {
                axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_BASE_URL}api/core/attendance/schedule/generate?id_hs_employee=${id_hs_employee}`,                
                }).then(function (response) {
                    window.location.reload();
                });                
            } catch (error) {
                alert(error)
            }
        }
    }

    const actions = [
        {
            child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>Lembur</button>,
            callback: (rowData) => handleOvertime(rowData)
        }
    ];

    return (
        <div>
            <div className='flex justify-between mb-4'>
                <h1>Attendance</h1>                
                {/* <div>
                    <button
                        className="text-white text-sm rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 my-2"
                        type="button"
                        onClick={handleGenerate}>
                        <FontAwesomeIcon icon={faWaveSquare} color="white" /> Terbitkan Jadwal
                    </button>
                </div>              */}
            </div>
            <DataTablePage
                url={`${process.env.REACT_APP_API_URL}/api/core/attendance/attendance?id_hs_employee=${id_hs_employee}`}
                columns={columns}
                // data={data}
            />
        </div>
    );
};

export default Attendance;
