import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../component/Table/DataTable';
import { confirmDelete } from '../../pages/Activity/Helpers/PromptUtils';

const User = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Define columns
    const columns = [
        {
            Header: 'ID',
            accessor: 'id',
        },
        {
            Header: 'Bisnis',
            accessor: 'project_name',
        },
        {
            Header: 'Nama',
            accessor: 'name',
        },
        {
            Header: 'Username',
            accessor: 'username',
        },
        {
            Header: 'Role',
            accessor: 'role_user',
        },
    ];

    // Fetch data from API
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}api/core/user/user`)
            .then(response => response.json())
            .then(data => {
                setData(data); // Set fetched data
                setLoading(false); // Data fetching complete
            })
            .catch(error => {
                setError(error); // Handle errors
                setLoading(false); // Data fetching complete
            });
    }, []);

    const handleEdit = (rowData) => {
        navigate(`/edit-user/${rowData.id}`);
    };

    const handleRemove = (rowData) => {
        const confirmed = confirmDelete('Are you sure you want to delete this module?');
        if (confirmed) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_API_BASE_URL + 'api/core/user/user/delete',
                data: {
                    id: rowData.id
                }
            }).then(function (response) {
                window.location.reload();
            });            
        }
    }

    const actions = [{
        child: <button className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faTrash} color="white" />
        </button>,
        callback: (rowData) => handleRemove(rowData)
    },{
        child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>
            <FontAwesomeIcon icon={faEdit} color="white" />
        </button>,
        callback: (rowData) => handleEdit(rowData)
    }];

    // Conditional rendering
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <div className='flex justify-between'>
                <h1>User</h1>
                <button
                    className="text-white text-xs rounded focus:ring bg-blue-500 hover:bg-blue-700 p-2 my-2"
                    type="button"
                    onClick={() => {
                        navigate('/add-user');
                    }}>
                    <FontAwesomeIcon icon={faAdd} color="white" /> Tambah
                </button>
            </div>
            <DataTable
                columns={columns}
                data={data}
                actions={actions}

            />
        </div>
    );
};

export default User;
