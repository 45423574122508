// src/components/SortData.js
import React, { useEffect } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

const SortData = () => {
    const [items, setItems] = React.useState([
        { id: 1, name: 'Item A' },
        { id: 2, name: 'Item B' },
        { id: 3, name: 'Item C' },
        { id: 4, name: 'Item D' },
        { id: 5, name: 'Item E' },
        { id: 6, name: 'Item F' },
        { id: 7, name: 'Item G' },
        { id: 8, name: 'Item H' },
        { id: 9, name: 'Item I' }
    ]);

    const onSortEnd = (oldIndex, newIndex) => {
        console.log(oldIndex, newIndex);
        setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    };

    useEffect(() => {
        console.log(items);
    }, [items]);

    return (
        <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
            {items.map((item, index) => (
                <SortableItem key={item.id} index={index}>
                    <div className="item">{item.name}</div>
                </SortableItem>
            ))}
        </SortableList>
    );
};

export default SortData;
