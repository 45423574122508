import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../helpers/Jwt';

const EntryMenu = () => {
    const { id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);

    const [optionModule, setOptionModule] = useState([]);
    const [loadingModule, setLoadingModule] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        link: '',
    });

    useEffect(() => {
        fetchOptionProject();
        if (id) {
            fetchData(id);
        }
    }, [id]);

    const fetchData = async (id) => {
        setLoading(true);
        try {
            await axios.get(`${process.env.REACT_APP_API_URL}/api/system/menu/detail?id=${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }).then( response => {
                const { id_project, id_module, name, link } = response.data.data;
                fetchOptionModule(id_project);
                setInitialValues({
                    id_project: id_project || '',
                    id_module: id_module || '',
                    name: name || '',
                    link: link || '',                    
                });
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchOptionModule = async (id_project) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/system/module/option?id_project=${id_project}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            setOptionModule(response.data.data);
        } catch (error) {
            alert(error);
        } finally {
            setLoadingModule(false);
        }
    };

    const fetchOptionProject = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            setOptionProject(response.data);
        } catch (error) {
            alert(error);
        } finally {
            setLoadingProject(false);
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required')
    });

    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_module: values.id_module,
                platform: values.platform,
                name: values.name,
                link: values.link,
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/system/menu/save`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/system/menu/save`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
            }

            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 bg-white shadow-md rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Menu</h2>
            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                    Project
                                </label>
                                {loadingProject ? (
                                    <h1>Loading...</h1>
                                ) : (
                                    <>
                                        <Field
                                            as="select"
                                            name="id_project"
                                            className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            onChange={(e) => {
                                                const selectedProjectId = e.target.value;
                                                setFieldValue("id_project", selectedProjectId);
                                                fetchOptionModule(selectedProjectId);
                                            }}
                                        >
                                            <option value="" label="Pilih Project" />
                                            {optionProject.map((project) => (
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="id_project" component="p" className="text-red-500 text-xs mt-1" />
                                    </>
                                )}
                            </div>
                            <div className='flex-1 px-2'>
                                <label htmlFor="id_module" className="block text-sm font-medium text-gray-700">
                                    Module
                                </label>
                                {loadingModule ? (
                                    <h1>Loading...</h1>
                                ) : (
                                    <>
                                        <Field
                                            as="select"
                                            name="id_module"
                                            className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        >
                                            <option value="" label="Pilih Module" />
                                            {optionModule.map((modul) => (
                                                <option key={modul.id} value={modul.id}>
                                                    {modul.name}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="id_module" component="p" className="text-red-500 text-xs mt-1" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="gap-4 px-6 py-2">
                            <label htmlFor="platform">Platform</label>
                            <Field
                                as="select"
                                name="platform"
                                className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                onChange={(e) => setFieldValue("platform", e.target.value)}
                            >
                                <option value="" label="Pilih Platform" />
                                <option value="Dashboard" label="Platform Website" />
                                <option value="Mobile" label="Platform Mobile" />
                            </Field>
                        </div>
                        <div className="gap-4 px-6 py-2">
                            <label htmlFor="name">Name</label>
                            <Field
                                className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text"
                                name="name"
                            />
                            <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="gap-4 px-6 py-2">
                            <label htmlFor="link">Link</label>
                            <Field
                                className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                type="text"
                                name="link"
                            />
                            <ErrorMessage name="link" component="p" className='text-red-500 text-xs mt-1' />
                        </div>
                        <div className="mt-4">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryMenu;
