// src/context/AuthContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import { removeToken, decodeToken, getToken, setToken } from '../helpers/Jwt'

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        user: null,
    });

    useEffect(() => {
        const checkAuth = () => {
            try {
                const token = getToken();
                if (token) {
                    const user = decodeToken();
                    setAuth({
                        isAuthenticated: true,
                        user,
                    });
                } else {
                    setAuth({
                        isAuthenticated: false,
                        user: null,
                    });
                }
            } catch (error) {
                setAuth({
                    isAuthenticated: false,
                    user: null,
                });
            } finally {
                setLoading(false);
            }
        };
        checkAuth();
    }, []);

    const login = (token) => {
        setToken(token);
        const user = decodeToken();
        setAuth({
            isAuthenticated: true,
            user,
        });
    };

    const logout = () => {
        removeToken();
        setAuth({
            isAuthenticated: false,
            user: null,
        });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);