import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyInput from '../../../helpers/CurrencyInput';
import CustomInput from '../../../helpers/CustomInput';
import { getToken } from '../../../helpers/Jwt';

const EntryEmployee = () => {
    const { id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);

    const [optionOutlet, setOptionOutlet] = useState([]);
    const [loadingOutlet, setLoadingOutlet] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        username: 'username',
        password: 'P@ssw0rd',
        confirm_password: 'P@ssw0rd',
        role_user: 'waiters',
        name: 'Name',
        born: '2024-09-04',
        payroll_date: '28',
        main_days: '22',
        salary: '5000000',
        salary_day: '100000',
        overtime_rate: '100000',
    });

    const fetchOptionOutlet = async (id_project) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/outlet/outlet?id_project=${id_project}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            setOptionOutlet(response.data);
        } catch (error) {
            alert(error);
        } finally {
            setLoadingOutlet(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (id) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/core/attendance/employee/detail?id=${id}`);
                    const { name, born, payroll_date, main_days, salary, salary_day, overtime_rate } = response.data;
                    const { id_project, id_outlet, username, role_user } = response.data.user;       
                    
                    fetchOptionOutlet(id_project);

                    setInitialValues(prevValues => ({
                        ...prevValues,
                        id_project: id_project || '',
                        id_outlet: id_outlet || '',
                        username: username || '',
                        role_user: role_user || '',
                        name: name || '',
                        born: born || '',
                        payroll_date: payroll_date || '',
                        main_days: main_days || '',
                        salary: salary || '',
                        salary_day: salary_day || '',
                        overtime_rate: overtime_rate || '',
                    }));
                }
            } catch (error) {
                alert(error.response.data);
            } finally {
                setLoading(false);
            }
        };

        const fetchOptionProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                setOptionProject(response.data);                
                fetchData();
            } catch (error) {
                alert(error);
            } finally {
                setLoadingProject(false);
            }
        };        

        fetchOptionProject();
        
    }, [id]);

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        id_project: Yup.string()
            .required('Project is required'),
        // id_outlet: Yup.string()
        //     .required('Outlet is required'),
        username: Yup.string()
            .required('Username is required')
            .min(4, 'Username must be at least 4 characters long')
            .matches(/^[a-zA-Z0-9_]+$/, 'Username can only contain letters, numbers, and underscores'),

        password: Yup.string()
            .required('Password is required')
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[\W_]/, 'Password must contain at least one special character'),

        confirm_password: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),

        role_user: Yup.string()
            .required('Role user is required'),
        // .oneOf(['admin', 'user'], 'Role user must be either admin or user'),

        name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters long'),

        payroll_date: Yup.string()
            .required('Tanggal Penggajian is required'),

        main_days: Yup.string()
            .required('Jumlah hari kerja is required'),

        salary: Yup.string()
            .required('Gaji is required'),

        overtime_rate: Yup.string()
            .required('Tarif Lembur/Jam is required'),

    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: values.id_project,
                id_outlet: values.id_outlet,
                name: values.name,
                username: values.username,
                password: values.password,
                role_user: values.role_user,
                name: values.name,
                born: values.born,
                payroll_date: values.payroll_date,
                main_days: values.main_days,
                salary: values.salary,
                salary_day: values.salary_day,
                overtime_rate: values.overtime_rate
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/employee`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/employee`, formData);
            }

            // console.log(values);
            navigate(-1);
        } catch (error) {
            alert(error.response.data);
        } finally {
            requests.setSubmitting(false);
        }
    };

    const calculateSalaryDay = (salary, main_days) => {
        if (salary && main_days) {
            return parseInt(salary / main_days)
        }
        return 0;
    }

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Employee</h2>
            <Formik
                enableReintialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, getFieldProps }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                    Project
                                </label>
                                {loadingProject ? (
                                    <h1>Loading...</h1>
                                ) : (
                                    <>
                                        <Field
                                            as="select"
                                            name="id_project"
                                            className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            onChange={(e) => {
                                                const selectedProjectId = e.target.value;
                                                setFieldValue("id_project", selectedProjectId); // Update Formik value
                                                fetchOptionOutlet(selectedProjectId); // Call fetchOptionOutlet with the selected project ID
                                            }}
                                        >
                                            <option value="" label="Pilih Project" />
                                            {optionProject.map((project) => (
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="id_project" component="p" className="text-red-500 text-xs mt-1" />
                                    </>
                                )}
                            </div>
                            <div className='flex-1 px-2'>
                                <label htmlFor="id_outlet" className="block text-sm font-medium text-gray-700">
                                    Outlet
                                </label>
                                {loadingOutlet ? (
                                    <h1>Loading...</h1>
                                ) : (
                                    <>
                                        <Field
                                            as="select"
                                            name="id_outlet"
                                            className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                        >
                                            <option value="" label="Pilih Outlet" />
                                            {optionOutlet.map((outlet) => (
                                                <option key={outlet.id} value={outlet.id}>
                                                    {outlet.name}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="id_outlet" component="p" className="text-red-500 text-xs mt-1" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="username">Username</label>
                                <Field
                                    name="username"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="text" />
                                <ErrorMessage name="username" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                            <div className='flex-1 px-2'>
                                <label htmlFor="role_user">Role User</label>
                                <Field
                                    name="role_user"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="text" />
                                <ErrorMessage name="role_user" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="password">Password</label>
                                <Field
                                    name="password"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="password" />
                                <ErrorMessage name="password" component="p" className='text-red-500 text-xs mt-1' />                                
                            </div>
                            <div className='flex-1 px-2'>
                                <label htmlFor="confirm_password">Password</label>
                                <Field
                                    name="confirm_password"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="password" />
                                <ErrorMessage name="confirm_password" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="name">Name</label>
                                <Field
                                    name="name"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="text" />
                                <ErrorMessage name="name" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                            <div className='flex-1 px-2'>
                                <label htmlFor="born">Tgl Lahir</label>
                                <Field
                                    name="born"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="date" />
                                <ErrorMessage name="born" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="payroll_date">Tanggal Penggajian</label>
                                <Field
                                    name="payroll_date"
                                    type="number"
                                    max={28}
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200" />
                                <ErrorMessage name="payroll_date" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                            <div className='flex-1 px-2'>
                                <label htmlFor="born">Total Hari Kerja/Bulan</label>
                                <Field
                                    name="main_days"
                                    max={28}
                                    component={CustomInput}
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    onChange={(value) => {
                                        const main_days = value;
                                        const salary = getFieldProps('salary').value;

                                        setFieldValue('main_days', main_days)
                                        setFieldValue('salary_day', calculateSalaryDay(salary, main_days))
                                    }}
                                    onBlur={handleBlur}
                                    type="number" />
                                <ErrorMessage name="main_days" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                        </div>
                        <div className='flex flex-wrap px-4 py-2'>
                            <div className='w-full sm:w-1/1 md:w-1/2 lg:w-1/3 px-2 mb-4'>
                                <label htmlFor="born">Gaji</label>
                                <CurrencyInput
                                    id="salary"
                                    name="salary"
                                    value={values.salary}
                                    onChange={(value) => {
                                        const main_days = getFieldProps('main_days').value;
                                        const salary = value;

                                        setFieldValue('salary', salary)
                                        setFieldValue('salary_day', calculateSalaryDay(salary, main_days))
                                    }}
                                    currency="IDR"
                                    placeholder="Masukkan jumlah"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                />
                                <ErrorMessage name="salary" component="p" className='text-red-500 text-xs mt-1' />

                            </div>
                            <div className='w-full sm:w-1/1 md:w-1/2 lg:w-1/3 px-2 mb-4'>
                                <label htmlFor="born">Gaji Prorata</label>
                                <CurrencyInput
                                    disabled
                                    id="salary_day"
                                    name="salary_day"
                                    value={values.salary_day}
                                    currency="IDR"
                                    placeholder="Masukkan jumlah"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                />

                                <ErrorMessage name="salary_day" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                            <div className='w-full sm:w-1/1 md:w-1/2 lg:w-1/3 px-2 mb-4'>
                                <label htmlFor="born">Upah Lembur /Jam</label>
                                <CurrencyInput
                                    id="overtime_rate"
                                    name="overtime_rate"
                                    value={values.overtime_rate}
                                    onChange={(value) => setFieldValue('overtime_rate', value)}
                                    currency="IDR"
                                    placeholder="Masukkan jumlah"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                />

                                <ErrorMessage name="overtime_rate" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                        </div>

                        <div className="mt-4 px-5">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryEmployee;
