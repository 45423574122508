import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../../helpers/Jwt';

const EntryShift = () => {
    const { id } = useParams(); // Get 'id' from link params
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [optionProject, setOptionProject] = useState([]);
    const [loadingProject, setLoadingProject] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        shift: '',
        time_start: '',
        many_hours: '',
        time_break: '',
        hours_start_after: '',
        hours_start_before: '',
        hours_end_after: '',
        hours_end_before: ''
    });

    useEffect(() => {    
        const fetchData = async () => {
            setLoading(true);
            try {
                if (id) {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/attendance/shift/detail?id=${id}`);
                    const { shift, time_start, time_break, many_hours, hours_start_after, hours_start_before, hours_end_after, hours_end_before } = response.data;
                    setInitialValues({
                        id: id,
                        shift: shift || '',
                        time_start: time_start || '',
                        many_hours: many_hours || '',
                        time_break: time_break || '',
                        hours_start_after: hours_start_after || '',
                        hours_start_before: hours_start_before || '',
                        hours_end_after: hours_end_after || '',
                        hours_end_before: hours_end_before || ''
                    });                
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchOptionProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/core/project/project`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                setOptionProject(response.data);                
                fetchData();
            } catch (error) {
                alert(error);
            } finally {
                setLoadingProject(false);
            }
        };        

        fetchOptionProject();
    }, [id]);    

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        shift: Yup.string().required('shift is required'),
        time_start: Yup.string().required('timeStart is required'),
        many_hours: Yup.string().required('manyHours is required'),
        time_break: Yup.string().required("time_break is required"),
        hours_start_after: Yup.string().required('hoursStartAfter is required'),
        hours_start_before: Yup.string().required('hoursStartBefore is required'),
        hours_end_after: Yup.string().required('hoursEndAfter is required'),
        hours_end_before: Yup.string().required('hoursEndBefore is required'),
    });

    // Handle form submission
    const onSubmit = async (values, requests) => {
        try {
            const formData = {
                id_project: values.id_project,                
                shift: values.shift,
                time_start: values.time_start,
                many_hours: values.many_hours,
                time_break: values.time_break,
                hours_start_after: values.hours_start_after,
                hours_start_before: values.hours_start_before,
                hours_end_after: values.hours_end_after,
                hours_end_before: values.hours_end_before
            };

            if (id) {
                formData['id'] = id;
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/shift`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}api/core/attendance/shift`, formData);
            }
            
            navigate(-1);
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error, show alert or set form error state
        } finally {
            requests.setSubmitting(false);
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="mx-auto p-4 rounded">
            <h2 className="text-2xl font-bold mb-4">Entry Form Shift</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, getFieldProps }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="shift">Shift</label>
                                <Field
                                    name="shift"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="text" />
                                <ErrorMessage name="shift" component="p" className='text-red-500 text-xs mt-1' />
                            </div>
                            <div className='flex-1 px-2'>
                                <label htmlFor="id_project" className="block text-sm font-medium text-gray-700">
                                    Project
                                </label>
                                {loadingProject ? (
                                    <h1>Loading...</h1>
                                ) : (
                                    <>
                                        <Field
                                            as="select"
                                            name="id_project"
                                            className="my-2 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                            onChange={(e) => {
                                                const selectedProjectId = e.target.value;
                                                setFieldValue("id_project", selectedProjectId);                                                
                                            }}
                                        >
                                            <option value="" label="Pilih Project" />
                                            {optionProject.map((project) => (
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="id_project" component="p" className="text-red-500 text-xs mt-1" />
                                    </>
                                )}
                            </div>                            
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="time_start">Jam Masuk</label>
                                <Field
                                    name="time_start"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="time" />
                                <ErrorMessage name="time_start" component="p" className='text-red-500 text-xs mt-1' />
                            </div> 
                            <div className='flex-1 px-2'>
                                <label htmlFor="time_break">Jam Istirahat</label>
                                <Field
                                    name="time_break"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="time" />
                                <ErrorMessage name="time_break" component="p" className='text-red-500 text-xs mt-1' />
                            </div>    
                            <div className='flex-1 px-2'>
                                <label htmlFor="many_hours">Lama Jam Kerja</label>
                                <Field
                                    name="many_hours"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="number" />
                                <ErrorMessage name="many_hours" component="p" className='text-red-500 text-xs mt-1' />
                            </div>                            
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="hours_start_before">Waktu Masuk Kerja Dibuka</label>
                                <Field
                                    name="hours_start_before"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="number" />
                                <ErrorMessage name="hours_start_before" component="p" className='text-red-500 text-xs mt-1' />
                            </div> 
                            <div className='flex-1 px-2'>
                                <label htmlFor="hours_start_after">Waktu Toleransi Masuk Kerja</label>
                                <Field
                                    name="hours_start_after"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="number" />
                                <ErrorMessage name="hours_start_after" component="p" className='text-red-500 text-xs mt-1' />
                            </div>                            
                        </div>
                        <div className="flex flex-col md:flex-row gap-4 px-4 py-2">
                            <div className='flex-1 px-2'>
                                <label htmlFor="hours_end_before">Waktu Pulang Kerja Dibuka</label>
                                <Field
                                    name="hours_end_before"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="number" />
                                <ErrorMessage name="hours_end_before" component="p" className='text-red-500 text-xs mt-1' />
                            </div> 
                            <div className='flex-1 px-2'>
                                <label htmlFor="hours_end_after">Waktu Pulang Toleransi Kerja</label>
                                <Field
                                    name="hours_end_after"
                                    className="my-1 p-2 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                                    type="number" />
                                <ErrorMessage name="hours_end_after" component="p" className='text-red-500 text-xs mt-1' />
                            </div>                            
                        </div>
                        
                        <div className="mt-4 px-5">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                            >
                                {isSubmitting ? 'Submitting...' : 'Submit'}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EntryShift;
