import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import axios from 'axios';
import './DataTablePage.css'

const DataTablePage = ({ columns, url, actions }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortBy, setSortBy] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(url, {
                    params: {
                        offset: pageIndex,
                        limit: pageSize,
                        _sort: sortBy[0]?.id || 'id',
                        _order: sortBy[0]?.desc ? 'desc' : 'asc',
                    },
                });
                console.log(response.data);
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data', error);
            }
            setLoading(false);
        };

        fetchData();
    }, [pageIndex, pageSize, sortBy]);

    const handleAction = (rowData, callback) => {
        callback(rowData);
    };

    const columnsWithActions = useMemo(
        () => [
            ...columns,
            {
                Header: 'Actions',
                id: 'actions',
                Cell: ({ row }) => (

                    <div className="flex justify-center space-x-2">
                        {actions?.map((action, index) => {
                            return (
                                <button
                                    key={index}
                                    onClick={() => handleAction(row.original, action.callback)}
                                    className={action.className}>
                                    {action.child ?? action.title}
                                </button>
                            );
                        })}
                    </div>
                ),
            },
        ],
        [columns] // Add dependencies
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state
    } = useTable(
        {
            columns: columnsWithActions,
            data,
            manualSortBy: true,
            manualPagination: true,
            pageCount: Math.ceil(100 / pageSize), // Update this based on your data
            initialState: {
                pageIndex: 0,
                pageSize: 10,
            },
        },
        useSortBy,
        usePagination
    );

    return (
        <div className="overflow-x-auto">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    <table {...getTableProps()} className="min-w-full bg-white border border-gray-200">
                        <thead className="bg-gray-100">
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            key={column.id}>
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? ' 🔽'
                                                        : ' 🔼'
                                                    : ''}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row);
                                return (
                                    <tr key={row.id} {...row.getRowProps()} className="border-b border-gray-200 hover:bg-gray-50">
                                        {row.cells.map(cell => (
                                            <td
                                                {...cell.getCellProps()}
                                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                                                key={cell.column.id}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination-container">
                        <button
                            className="pagination-button"
                            onClick={() => setPageIndex(prev => Math.max(prev - 10, 0))}
                        // disabled={!state.canPreviousPage}
                        >
                            Previous
                        </button>
                        <span className="pagination-info">
                            Page {state.pageIndex + 1} of
                            {/* {state.pageOptions.length} */}
                        </span>
                        <button
                            className="pagination-button"
                            onClick={() => setPageIndex(prev => Math.min(prev + 10))}
                        // disabled={!state.canNextPage}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default DataTablePage;
