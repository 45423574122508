// src/Grid.js
import React from 'react';
import './Grid.css'; // Import the CSS file for styling

const Grid = ({ items, columns }) => {
    return (        
        <div className="grid-container" style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`}}>
            {items?.map((item, index) => (
                <div key={index} className="grid-item">
                    {item}
                </div>
            ))}
        </div>
    );
};

export default Grid;
