import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { confirmDelete } from '../../Activity/Helpers/PromptUtils';
import axios from 'axios';
import { formatCurrency } from '../../../helpers/CurrencyInput';
import DataTablePage from '../../../component/Table/DataTablePage';

const History = () => {
    const navigate = useNavigate();

    const columns = [
        {
            Header: 'ID',
            accessor: 'no_transaction',
        },
        {
            Header: 'Nama Pelanggan',
            accessor: 'customer_name',
        },
        {
            Header: 'Total Tagihan',
            accessor: 'amount',
            Cell: ({ value }) => formatCurrency(parseInt(value), 'IDR'),
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
    ];

    const actions = [
    //     {
    //     child: <button className='text-white text-xs rounded focus:ring bg-red-500 hover:bg-red-700 p-2 mx-1'>Hapus</button>,
    //     callback: (rowData) => handleRemove(rowData)
    // },{
    //     child: <button className='text-white text-xs rounded focus:ring bg-yellow-500 hover:bg-yellow-700 p-2 mx-1'>Ubah</button>,
    //     callback: (rowData) => handleEdit(rowData)
    // }
    ];

    return (
        <div>
            <div className='flex justify-between'>
                <h1>Product</h1>
            </div>
            <DataTablePage
                url={`${process.env.REACT_APP_API_URL}api/dashboard/base/transaction`}
                columns={columns}
                // actions={actions}
            />            
        </div>
    );
};

export default History;
