import React from 'react';
import './List.css'; // Import the updated CSS file

const List = ({ items }) => {
    return (
        <ul className="list-container">
            {items?.map((item, index) => (
                <li key={index} className="list-item">
                    {item}
                </li>
            ))}
        </ul>
    );
}

export default List;