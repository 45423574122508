import React from 'react'

export default function NotFoundPage() {
  return (
    <div>
      <h3>404</h3>
      <p>Page Not Found</p>
    </div>
  )
}
